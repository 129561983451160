import Button from "@mui/material/Button";
import { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import "./UploadSign.css";
import * as React from "react";
import { SuccessToast, ErrorToast } from "../../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../services/client";
import api from "../../../services/apiInterceptor.js";
import { setContractSign } from "../../../services/redux/reducer/addsign.js";
import useDrivePicker from "react-google-drive-picker";
import DropboxChooser from "react-dropbox-chooser";
import ScreenLoader from "../../loader/ScreenLoader.js";

export default function UploadSign(props) {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [openPicker, authResponse] = useDrivePicker();
  const [fileNames, setFileNames] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleOpenPicker = () => {
    openPicker({
      clientId:
        "704961265565-7qkgk1c1gcfp5lvnls4kf4acllgn1aj9.apps.googleusercontent.com",
      developerKey: "AIzaSyB4nnxhVHH3x56jRCDnDThfnvXMYKHhmYQ",
      viewId: "DOCS",
      // token: token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }

        if (data.docs && data.docs.length > 0) {
          // const fileId = data.docs[0].id;
          // const fileUrl = `https://drive.google.com/uc?export=view&id=${fileId}`;
          // setImageSrc(fileUrl);
          // console.log("google imgs ",fileUrl)
          // Access the URL of the first document in data.docs
          // dispatch(setContractSign(fileUrl));

          dispatch(setContractSign(data.docs[0].embedUrl));
          SuccessToast("Signature Uploaded Successfully");
        } else {
          console.log("No documents selected");
        }
        // dispatch(setContractSign(data?.docs));

        console.log(data);
      },
    });
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("Selected Signature file:", file);

    if (file) {
      setLoader(true);

      try {
        const formData = new FormData();
        formData.append("file", file);
        const res = await api.post(`${API_URL}/upload/uploadimage`, formData);

        if (res.status === 200) {
          setLoader(false);

          console.log("Signature Response", res);
          console.log("Signature Uploaded");
          setImageSrc(res?.data?.data);
          dispatch(setContractSign(res?.data?.data));
          console.log(res?.data?.data, "this is the SIGN URL");
          SuccessToast("Signature Uploaded Successfully");
          props.onHide();
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // const APP_KEY = "glccp41t338t9ap";
  const APP_KEY = "aap7woidfm3fzfp";
  const onSuccess = (files) => {
    console.log("DROPBOX UPLOADED FILE:", files);
    files.map((file) => {
      setFileNames((fileNames) => [...fileNames, file]);
    });
    dispatch(setContractSign(files[0].name));
    console.log("hi file", files);
    SuccessToast("Signature Uploaded Successfully");
    props.onHide();
  };
  // const ContractSign = useSelector((state) => state?.addsign.contractsign);
  // console.log("contract  sign is", ContractSign);

  const ContractSign = useSelector((state) => state?.addsign.contractsign);
  console.log("contract  sign is", ContractSign);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   console.log("Selected file:", file);
  //   setSelectedFile(file);
  //   SuccessToast("Sign Uploaded Successfully");

  //   // Show preview image if the selected file is an image
  //   if (file && file.type.startsWith("image/")) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setPreviewUrl(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setPreviewUrl(null);
  //   }
  // };
  return (
    <>
      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="upload-sign-modal"
      >
        <Modal.Header
          style={{ padding: "70px", paddingBottom: "0px", paddingTop: "60px" }}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="add-project__header upload-header"
          >
            <div className="add-project__main-header">
              <h6 className="mb-0 upload-sign-heading">Upload Signature</h6>
            </div>
            <div className="add-project__close add-contract-close">
              <img
                src="/Images/Projects/close.svg"
                alt="close-icon"
                onClick={props.onHide}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            padding: "70px",
            paddingTop: "45.5px",
            paddingBottom: "60px",
          }}
        >
          {loader && <ScreenLoader />}

          <div>
            <div>
              <div className="upload-option" onClick={handleUploadClick}>
                <img
                  src="/Images/Contract/device.svg"
                  alt="device"
                  className="upload-img"
                />
                <p className="option-name">From device</p>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>

              {/* {previewUrl && (
                <div>
                  <p>Selected File: {selectedFile.name}</p>
                  <img
                    src={previewUrl}
                    alt="Preview"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              )} */}
              <div
                className="upload-option pt-option"
                onClick={handleOpenPicker}
              >
                <img
                  src="/Images/Contract/google-drive.svg"
                  alt="google-drive"
                  className="upload-img"
                />
                <p className="option-name">Google drive</p>
              </div>
              {/* <div className="upload-option pt-option">
                <img
                  src="/Images/Contract/onedrive.svg"
                  alt="One-drive"
                  className="upload-img"
                />
                <p className="option-name">One drive</p>
              </div> */}

              <div className="pt-option">
                <DropboxChooser
                  appKey={APP_KEY}
                  success={(files) => onSuccess(files)}
                  cancel={() => console.log("closed")}
                  multiselect={true}
                >
                  {/* <br /> */}

                  <div className="upload-option ">
                    <img
                      src="/Images/Contract/dropbox.svg"
                      alt="Dropbox"
                      className="upload-img"
                    />
                    <p className="option-name">Dropbox</p>
                  </div>
                  <div className="dropbox"> </div>
                </DropboxChooser>
              </div>

              {/* <div className="upload-option pt-option">
                <img
                  src="/Images/Contract/dropbox.svg"
                  alt="Dropbox"
                  className="upload-img"
                />
                <p className="option-name">Dropbox</p>
              </div> */}
            </div>
            <div>
              <Button onClick={props.onHide} className="btn-save-sign">
                Save signature
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
