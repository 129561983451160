import React from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import "./HelpCenter.css";
import Header from "../../Components/Header/Header";
import { useMediaQuery } from "react-responsive";
import Dispute from "../../Components/Modals/Dispute/Dispute";
import { helpCenter } from "../../services/redux/middleware/helpCenter";
import { useDispatch, useSelector } from "react-redux";
import ScreenLoader from "../../Components/loader/ScreenLoader";
import { ErrorToast, SuccessToast } from "../../Components/toast/Toast";
import { getAllProjectDispute } from "../../services/redux/middleware/Project/project";

export default function HelpCenter() {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [email, setEmail] = useState("");
  const [phoneno, setPhoneNo] = useState("");
  const [loader, setLoader] = useState(false);

  const handlePhoneNoChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values
    const newValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    setPhoneNo(newValue);
  };

  const [modalShow, setModalShow] = useState(false);
  const formValidation = () => {
    if (!email) {
      ErrorToast("Please Enter Email");
      return false;
    } else if (!phoneno) {
      ErrorToast("Please Enter Contact Number");
      return false;
    }
  };

  async function handleDisputes() {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setLoader(true);
    try {
      const data = {
        email: email,
        contactNo: phoneno,
      };
      dispatch(helpCenter(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          console.log("help center", res?.payload?.data);

          SuccessToast("Added");
          setPhoneNo("");
          setEmail("");
          setModalShow(true);
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <>
      <>
        <Header headername={"Help center"} />
        <div className="help-body">
          <div className="help-r1">
            <Form.Group className="email-div">
              <Form.Label className="common-label">Email address</Form.Label>
              <div className="email-input-contain">
                <Form.Control
                  readOnly
                  type="email"
                  placeholder="Enter Email"
                  className="email-input-noicon"
                  value={"info@pocketfiller.com"}
                  // onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Form.Group>
         
          </div>

          <div className="help-r2-box">
            <p className="important-head">Important notice</p>
            <p className="imp-subtxt">
              For any issues or disputes regarding PocketFiler, please do not
              hesitate to reach out to our support team. <br/> We are committed to
              providing you with the best service possible and addressing any
              concerns you may have. <br/>You can contact us at info@pocketfiler.com,
              and our dedicated team will assist you promptlyYour satisfaction
              is our priority, .<br/>  and we are here to help resolve any problems you
              encounter.
            </p>
          </div>
          {/* <button
            className="helpCenter__btn"
            onClick={() => setModalShow(true)}
          >
            Disputes
          </button> */}
          {/* {modalShow && (
            <Dispute show={modalShow} onHide={() => setModalShow(false)} />
          )} */}
        </div>
      </>
    </>
  );
}
