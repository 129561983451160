import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import Dropdown from "react-bootstrap/Dropdown";
import Profile from "../../Pages/Profile/Profile";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserNotifications,
  NotificationStatus,
  markAllRead,
} from "../../services/redux/middleware/notification";
import { socket } from "../../services/socket";
import notifysound from "../../assets/audio/notification_sound.mp3";
import ScreenLoader from "../loader/ScreenLoader";
import { SuccessToast, ErrorToast } from "../toast/Toast";

export default function Header({ headername }) {
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(false);
  const [userId, setUserId] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const notificationRef = useRef(null);
  let audio = new Audio(notifysound);

  const handleProfile = () => {
    console.log("profile clicked");
    navigate("/Profile");
  };
  useEffect(() => {
    const userid = localStorage.getItem("_id");
    console.log("user id ", userid);
    setUserId(userid);
    dispatch(getUserNotifications(userid));
  }, [userId]);

  

  const notificationdata = useSelector(
    (state) => state?.getNotification?.myNotifications?.data
  );
  console.log("my notification is ", notificationdata);

  const Unreadnotification =
    notificationdata &&
    notificationdata?.some((item) => item && item?.NotifyRead === false);
  console.log("Unread Notifications ", Unreadnotification);

  const getAllNotifications = () => {
    audio.play();
    const userid = localStorage.getItem("_id");
    dispatch(getUserNotifications(userid));
  };
  useEffect(() => {
    const userid = localStorage.getItem("_id");
    socket.on("connect", () => {
      console.log("socket is connecting");
      socket.emit("join", userid);
    });
    if (userid) {
      socket.on("notification", getAllNotifications); // add client/associate
      socket.on("notificationForAssociate", getAllNotifications); // add client/associate

      socket.on("notification", getAllNotifications); //create contract
      socket.on("notification", getAllNotifications); // update contract

      socket.on("notify", getAllNotifications);
      socket.on("notify_share", getAllNotifications); //share contract

      socket.on("notification_project", getAllNotifications); //create project
      socket.on("notification_pupdate", getAllNotifications); //update project

      socket.on("login_notify", getAllNotifications); //LOGIN
      socket.on("signup_notify", getAllNotifications); //Signup
      socket.on("updateProfileNotify", getAllNotifications); //Profile Update

      socket.on("notification_request", getAllNotifications); //Req Document
      socket.on("notificationForRequest", getAllNotifications); //Req Document
      socket.on("notificationForsubscription", getAllNotifications); //Req Document
      socket.on("dispute_notification", getAllNotifications); //Create dispute

      socket.on("disconnect", (reason) => {});
      return () => {
        if (userid) {
          socket.off("notificationForAssociate", getAllNotifications); // add client/associate

          socket.off("notification", getAllNotifications);
          socket.off("notification", getAllNotifications);
          socket.off("notification", getAllNotifications);
          socket.off("notify", getAllNotifications);
          socket.off("notify_share", getAllNotifications);
          socket.off("notification_project", getAllNotifications);
          socket.off("notification_pupdate", getAllNotifications);
          socket.off("login_notify", getAllNotifications);
          socket.off("signup_notify", getAllNotifications);
          socket.off("updateProfileNotify", getAllNotifications);
          socket.off("notification_request", getAllNotifications);
          socket.off("notificationForRequest", getAllNotifications);
          socket.off("notificationForsubscription", getAllNotifications);
          socket.off("dispute_notification", getAllNotifications);
        }
        socket.off("connect", (reason) => {});
        socket.off("disconnect", (reason) => {});
      };
    }
  }, []);

  async function readnotification(id) {
    console.log("my notify id is", id);
    setLoader(true);
    try {
      const data = {
        notificationId: id,
      };
      dispatch(NotificationStatus(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          console.log("Notification Status Res", res?.payload?.data);
          SuccessToast("Read Success");
          dispatch(getUserNotifications(userId));
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }
  async function ReadAll() {
    setLoader(true);
    try {
      const data = {
        userId: userId,
      };
      dispatch(markAllRead(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          console.log("ALL READ NOTIFICATION Res", res?.payload?.data);
          // SuccessToast("Mark as Read Success");
          dispatch(getUserNotifications(userId));
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setNotification(false);
        console.log("my ref is", notificationRef.current)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="header-main">
        <p className="header-heading">{headername}</p>
        <div className="header-Rhs">
          {/* <img src="/Images/Dashboard/notification.svg" alt="notification" /> */}
          <div className="navbar__notification">
            <div className="navbar__notification__dropdown">
              {Unreadnotification ? (
                <img
                  src="/Images/Dashboard/notification copy.svg"
                  alt="notification"
                  className="notification-img-unread"
                  onClick={(e) => {
                    e.stopPropagation();
                    setNotification(!notification);
                  }}
                />
              ) : (
                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    setNotification(!notification);
                  }}
                  src="/Images/Dashboard/notification.svg"
                  alt="notification"
                  className="notification-img"
                />
              )}
            </div>
          </div>
          <img
            src="/Images/Dashboard/profile.svg"
            alt="profile"
            className="prof-img"
            onClick={handleProfile}
          />
        </div>

        {notification && (
          <div className="notification-drop"
          ref={notificationRef}
          >
            <div className="notify-top">
              <h2 className="navbar__notification__dropdown__title">Today</h2>
              <p className="mark-read" onClick={ReadAll}>
                Mark as read
              </p>
            </div>

            {/* <div className="notify-cards">
              <div href="">
                <div className="notify-cancel">
                  <div>
                    <img
                      src="/Images/Dashboard/notigy-lock-alert.svg"
                      alt="notify-lock"
                    />
                  </div>
                  <div>
                    <p className="Contract-cancel-head">hel</p>
                    <p className="Cancel-des">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      <br></br> voluptatem accusantium doloremque.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="notify-cancel notify-pt">
                  <div>
                    <img
                      src="/Images/Dashboard/notify-lock-icon.svg"
                      alt="notify-lock"
                    />
                  </div>

                  <div className="notify-bluedot-subtxt">
                    <div>
                      <p className="Contract-cancel-head">Password changed</p>

                      <p className="Cancel-des">
                        Your account password successfully updated.
                      </p>
                    </div>

                    <div className="blue-dot"></div>
                  </div>
                </div>
              </div>

              <h2 className="navbar__notification__dropdown__title notify-day-pt">
                Yesterday
              </h2>

              <div href="">
                <div className="notify-cancel">
                  <div>
                    <img
                      src="/Images/Dashboard/notigy-lock-alert.svg"
                      alt="notify-lock"
                    />
                  </div>
                  <div>
                    <p className="Contract-cancel-head">Contract cancel</p>
                    <p className="Cancel-des">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      <br></br> voluptatem accusantium doloremque.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="notify-cancel notify-pt">
                  <div>
                    <img
                      src="/Images/Dashboard/notify-lock-icon.svg"
                      alt="notify-lock"
                    />
                  </div>

                  <div className="notify-bluedot-subtxt">
                    <div>
                      <p className="Contract-cancel-head">Password changed</p>

                      <p className="Cancel-des">
                        Your account password successfully updated.
                      </p>
                    </div>

                    <div className="blue-dot"></div>
                  </div>
                </div>
              </div>

              <div href="">
                <div className="notify-cancel notify-pt">
                  <div>
                    <img
                      src="/Images/Dashboard/notigy-lock-alert.svg"
                      alt="notify-lock"
                    />
                  </div>
                  <div>
                    <p className="Contract-cancel-head">Contract cancel</p>
                    <p className="Cancel-des">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      <br></br> voluptatem accusantium doloremque.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="notify-cancel notify-pt">
                  <div>
                    <img
                      src="/Images/Dashboard/notify-lock-icon.svg"
                      alt="notify-lock"
                    />
                  </div>

                  <div className="notify-bluedot-subtxt">
                    <div>
                      <p className="Contract-cancel-head">Password changed</p>

                      <p className="Cancel-des">
                        Your account password successfully updated.
                      </p>
                    </div>

                    <div className="blue-dot"></div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="notify-cards">
              {notificationdata && notificationdata.length > 0 ? (
                notificationdata.map((item, index) => (
                  <div
                    key={index}
                    className="notify-cancel notify-pt"
                    onClick={() => readnotification(item?.id)}
                  >
                    <div>
                      <img
                        src="/Images/Dashboard/notigy-lock-alert.svg"
                        alt="notify-lock"
                      />
                    </div>
                    <div className=" notify-bluedot-subtxt">
                      <div>
                        <p className="Contract-cancel-head">{item?.action}</p>
                        <p className="Cancel-des">{item?.msg}</p>
                      </div>

                      {item?.NotifyRead === false && (
                        <div className="blue-dot"></div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No notifications available</p>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
