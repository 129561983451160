import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getContract = createAsyncThunk("getContract", async (data) => {
    try {
      const res = await api.get(`${API_URL}/contract/getfourContract/${data}`);
      // localStorage.setItem("token", res?.data?.token);
      console.log("inside get contract", res)
      return {
        status: res?.status,
        data: res?.data?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  });



  // for add client

  export const addClient = createAsyncThunk("addClient", async (data) => {
    try {
      console.log("inside get contract",data)
      const res = await api.post(`${API_URL}/association/addAssociation`,data); 
      // localStorage.setItem("token", res?.data?.token);
      return {
        status: res?.status,
        data: res?.data?.data,
      };
    } catch (error) {
      console.log("thjis is the error",error)
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  });


  export const updateClient = createAsyncThunk("updateClient", async (data) => {
    try {
      
      const res = await api.put(`${API_URL}/association/updateStatus`,data); 
      // localStorage.setItem("token", res?.data?.token);
      console.log("inside get contract",data)
      return {
        status: res?.status,
        data: res?.data?.data,
      };
    } catch (error) {
      console.log("this is the error",error)
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  });

  export const removeClients = createAsyncThunk("removeClients", async (data) => {
    try {
      console.log(data,"inside the remove client")
      
      const res = await api.delete(`${API_URL}/association/removeAssociation/${data}`); 
      // localStorage.setItem("token", res?.data?.token);
      console.log("remove Client",data)
      return {
        status: res?.status,
        data: res?.data?.data,
      };
    } catch (error) {
      console.log("this is the error",error)
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  });

  export const deleteProfile = createAsyncThunk("deleteProfile", async (data) => {
    try {
      console.log(data,"before delete profile")
      const res = await api.post(`${API_URL}/auth/requestDeleteAccount`,data); 
      console.log("delete profile",data)
      return {
        status: res?.status,
        data: res?.data?.data,
      };
    } catch (error) {
      console.log("this is the error",error)
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  });

  export const getClient = createAsyncThunk("getClient", async (data) => {
    try {
      const res = await api.get(`${API_URL}/association/getAssociatess/${data?.id}?page=${data?.page}`);
      // localStorage.setItem("token", res?.data?.token);
      console.log("inside get all Clients", res)
      return {
        status: res?.status,
        data: res?.data?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  });

  export const getAcceptClient = createAsyncThunk("getAcceptClient", async (data) => {
    try {
      const res = await api.get(`${API_URL}/association/getAssociatesUser/${data?.id}?page=${data?.page}`);
      // localStorage.setItem("token", res?.data?.token);
      console.log("Accept Clients", res)
      return {
        status: res?.status,
        data: res?.data?.data,
      };
    } catch (error) {
      return {
        message: error?.response?.data?.error,
        status: error?.response?.status,
      };
    }
  });