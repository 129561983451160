import React, { useState, useRef, useEffect } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { setContractEditor } from "../services/redux/reducer/addcontracteditor";

var modules = {
  toolbar: [
    [{ header: ["Heading", 1, 2, 3, 4, 5, 6] }],
    [{ size: ["normal", "small", "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    ["clean"],
    [],
  ],
};

var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];
export default function TestingEditior({ imgcontent }) {
  const dispatch = useDispatch();
  const [valuecontent, setvaluecontent] = useState();
  console.log("Editor Content Entered new is ", imgcontent);
  const handleProcedureContentChange = (content) => {
    console.log("Editor Content image---->", imgcontent);

    if (imgcontent !== null) {
      console.log("this is edotor content",content)
      setvaluecontent(content);
      dispatch(setContractEditor(content));
    } else {
      setvaluecontent(content);
      dispatch(setContractEditor(content));
    }
  };
  useEffect(() => {
    if (imgcontent !== null) {
      setvaluecontent(imgcontent);
      dispatch(setContractEditor(imgcontent));
    }
  }, [imgcontent]);

  return (
    <ReactQuill
      theme="snow"
      value={valuecontent}
      onChange={handleProcedureContentChange}
      modules={modules}
      formats={formats}
      placeholder="Type here...."
      style={{ height: "100%", width: "940px" }}
    />
  );
}
