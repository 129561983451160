import React, { useRef, useEffect,useState } from "react";
import { useParams,useLocation } from "react-router-dom";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { useNavigate } from "react-router-dom";




const Vedio = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('ProjectID');
  const { roomId } = useParams();
  const navigate = useNavigate();
  const myMeetingRef = useRef(null);

  const [ userName, setUserName]=useState();


  // const myMeeting = async (element) => {
  //   const appID = 537595066;
  //   const serverSecret = "8e023e449db6d1e5b00adeada6f45012";
  //   const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
  //     appID,
  //     serverSecret,
  //     roomId,
  //     Date.now().toString(),
  //     "Zyn Ali"
  //     // `${userData?.firstName} ${userData?.lastName}`
  //   );
  //   const zg = ZegoUIKitPrebuilt.create(kitToken);
  //   zg.joinRoom({
  //     container: element,
  //     showPreJoinView: false,
  //     // maxUsers: 2,
  //     showLeavingView: false,
  //     turnOnCameraWhenJoining: true,
  //     showScreenSharingButton: true,
  //     showTextChat:false,
  //     onLeaveRoom: async () => {
  //       navigate(`/ProjectActivities/${projectId}`)
  //     },

  //     scenario: {
  //       mode: ZegoUIKitPrebuilt.GroupCall,
  //     },
  //   });
  // };
 useEffect(() => {
    const username = localStorage.getItem("name");
    console.log("user name is ", username);
   setUserName(username)
  }, []);
  
  useEffect(() => {
    const myMeeting = async (element) => {
      // generate Kit Token
      const appID = 1051157449;
      const serverSecret = "4648e3f0a8c2a0e26fdfb54f726c2a00";
      const username = localStorage.getItem("name");
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
        appID,
        serverSecret,
        roomId,
        Date.now().toString(),
        username
        // `${userData?.firstName} ${userData?.lastName}`
      );

      // Create instance object from Kit Token.
      const zg = ZegoUIKitPrebuilt.create(kitToken);

      zg.joinRoom({
        container: element,
        showPreJoinView: false,
        // maxUsers: 2,
        showLeavingView: false,
        turnOnCameraWhenJoining: true,
        showScreenSharingButton: true,
        showTextChat:false,
        onLeaveRoom: async () => {
          navigate(`/ProjectActivities/${projectId}`)
        },
  
        scenario: {
          mode: ZegoUIKitPrebuilt.GroupCall,
        },
      });
    };

    if (myMeetingRef.current) {
      myMeeting(myMeetingRef.current);
    }

    // Cleanup function
    return () => {
      // Clear ref
      myMeetingRef.current = null;
    };

  }, [projectId, roomId, navigate]);

  return (
    <div>
      <div ref={myMeetingRef} style={{ width: "100vw", height: "100vh" }} />
    </div>
  );
};

export default Vedio;
