import React from "react";
import "./chatbox.css";
import { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ScreenLoader from "../loader/ScreenLoader";
import UploadDocument from "../Modals/UploadDocuments/UploadDocument";
import {
  ChatCall,
  chatSendMsg,
  getChatHistory,
} from "../../services/redux/middleware/Project/project";
import { SuccessToast, ErrorToast } from "../toast/Toast";
import { socket } from "../../services/socket";
import MsgNotifySound from "../../assets/audio/message-notification-190034.mp3";

const Chatbox = ({ disputing }) => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState();
  const [msgs, setmsg] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { projectid } = useParams();
  const [modalShow, setModalShow] = useState(false);

  let audio = new Audio(MsgNotifySound);
  const messagesEndRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault(); // Prevent default form submission behavior
      SendMsg();
    }
  };

  useEffect(() => {
    const userid = localStorage.getItem("_id");
    console.log("user id ", userid);
    setUserId(userid);
  }, [userId]);

  useEffect(() => {
    console.log("id is", projectid);
    dispatch(getChatHistory(projectid));
  }, []);

  const GetMessengerHistory = () => {
    audio.play();
    dispatch(getChatHistory(projectid));
  };

  useEffect(() => {
    const userid = localStorage.getItem("_id");
    socket.on("connect", () => {
      // audio.play();
      console.log("socket is connecting");
      socket.emit("join", userid);
    });
    if (userid && projectid) {
      socket.on("newMessengerHistory", GetMessengerHistory);

      socket.on("disconnect", (reason) => {});
      return () => {
        if (userid && projectid) {
          socket.off("newMessengerHistory", GetMessengerHistory);
        }
        socket.off("connect", (reason) => {});
        socket.off("disconnect", (reason) => {});
      };
    }
  }, []);

  const host = window.location.host;
  console.log("MY HOST IS", host);
  


  function generateRandomNumber(length) {
    const digits = "0123456789";
    let randomNumber = "";
    for (let i = 0; i < length; i++) {
      randomNumber += digits[Math.floor(Math.random() * digits.length)];
    }
    return randomNumber;
  }

  async function handleJoinAudioRoom() {
    setLoader(true);
    const uniqueAudioRoomID = generateRandomNumber(7);

    try {
      const data = {
        projectId: projectid,
        userId: userId,
        status: "started",
        activity: "Audio-Call",
        // callUrl: `${host}/ProjectActivities/AudioCAll/${uniqueAudioRoomID}?ProjectID=${projectid}`,
      };
     

      dispatch(ChatCall(data)).then((res) => {
        if (res?.status === 200) {
          setLoader(false);
          console.log("Audio Call Data", res?.payload?.data);
          const dataaudio = {
            projectId: projectid,
            userId: userId,
            activityMsg: "Audio-Call",

            msgUrl: `${host}/ProjectActivities/AudioCAll/${uniqueAudioRoomID}?ProjectID=${projectid}`,
          };
          dispatch(chatSendMsg(dataaudio));
          window.open(
            `/ProjectActivities/AudioCall/${uniqueAudioRoomID}?ProjectID=${projectid}`,
            "_blank"
          );
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function handleJoinVideoRoom() {
    setLoader(true);
    const uniqueVideoRoomID = generateRandomNumber(7);

    try {
      const data = {
        projectId: projectid,
        userId: userId,
        status: "started",
        activity: "Video-Call",
        callUrl: `${host}/ProjectActivities/VideoCall/${uniqueVideoRoomID}?ProjectID=${projectid}`,
      };

      dispatch(ChatCall(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          console.log("Video Call Data", res?.payload?.data);
          // SuccessToast("Video Call is going to start");
          const datavideo = {
            projectId: projectid,
            userId: userId,
            msgUrl: `${host}/ProjectActivities/VideoCall/${uniqueVideoRoomID}?ProjectID=${projectid}`,
            activityMsg: "Video-Call ",
          };
          dispatch(chatSendMsg(datavideo));
          window.open(
            `/ProjectActivities/VideoCall/${uniqueVideoRoomID}?ProjectID=${projectid}`,
            "_blank"
          );
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function SendMsg() {
    setLoader(true);
    try {
      const data = {
        projectId: projectid,
        userId: userId,
        msg: msgs,
      };

      dispatch(chatSendMsg(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          console.log("Msg send success", res?.payload?.data);
          // SuccessToast("Message sent successfully");
          setmsg("");
          dispatch(getChatHistory(projectid));
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const formatTime = (createdAt) => {
    if (!createdAt) return ""; // handle case where createdAt is undefined or null

    const date = new Date(createdAt);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be converted to 12

    // Padding minutes with zero if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;
  };
  const myCHat = useSelector(
    (state) => state?.getChathistory?.myChatHistory?.data
  );

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom("auto");
  }, [myCHat]);

  const ProjectData = useSelector(
    (state) => state?.getviewproject?.viewProject?.data
  );

  const handleClickCall = (url) => {
    if (url) {
      const fullUrl = url.startsWith("http") ? url : `http://${url}`;
      window.open(fullUrl, "_blank");
    }
  };

  // console.log("my chat is ", myCHat);
  // const handleJoinRoom = useCallback(() => {

  //   navigate(`/ProjectActivities/Call/1234567`);
  // }, [navigate, userId]);
  // const handlevedioJoinRoom = useCallback(() => {
  //   navigate(`/ProjectActivities/Vedio/1234567`);
  // }, [navigate, userId]);

  return (
    <div className="chatBox__main ">
      <div className="chatBox__main-header">
        <div className="chatBox__main-header_left">
          {/* <div className="chatBox__main-header_left-img">
            <img src="/Images/Projects/jennifer.svg" alt="/" />
          </div> */}
          <div className="chatBox__main-header_left-txt">
            <h2>{ProjectData?.title}</h2>
            <img
              src="/Images/Projects/recording-01.svg"
              alt="recording"
              className="record-img"
            />
            <p className="every-txt">
              Everything will be recorded at PocketFiler{" "}
            </p>
          </div>
        </div>
        <div className="chatBox__main-header_right">
          {disputing ? (
            <button
              className="ProjectActivities__top-box_header-btn2"
              onClick={() => setModalShow(true)}
            >
              Upload documents
            </button>
          ) : (
            <div className="chatBox__main-header_right">
              <img
                onClick={handleJoinAudioRoom}
                src="/Images/Projects/call.svg"
                alt="/"
                className="call-img"
              />
              <img
                src="/Images/Projects/vediocall.svg"
                alt="/"
                className="call-img"
                onClick={handleJoinVideoRoom}
              />
            </div>
          )}
        </div>
      </div>
      <div className="chatBox__border"></div>

      {/* chat */}
      {/* <div className="chatBox__main-sms">
        <div className="chatBox__main-sms__box">
          <div className="chatBox__main-sms__box-img">
            <img
              src="/Images/Projects/user-icon.svg"
              alt="/"
              className="user-img-chat"
            />
            <div className="chatBox__main-sms__box-text">
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s,
              </p>
            </div>
          </div>
          <p className="chatBox__main-sms__box-date">Today ,10:55pm</p>
        </div>
      </div> */}

      <div className="msgs-container">
        {myCHat &&
          myCHat.map((item, index) => (
            <>
              <>
                {item?.msg !== null && (
                  <div
                    className={
                      item?.userId == userId
                        ? "chatBox__main-sms1"
                        : "chatBox__main-sms"
                    }
                  >
                    <div
                      className={
                        item?.userId == userId
                          ? "chatBox__main-sms__box1"
                          : "chatBox__main-sms__box"
                      }
                    >
                      <div className="chatBox__main-sms__box-img">
                        {item?.userId != userId && (
                          <img
                            src={item?.user?.profilePicture}
                            alt="profile"
                            width={"28px"}
                            height={"28px"}
                            style={{
                              borderRadius: "50%",
                            }}
                          />
                        )}
                        <div
                          className={
                            item?.userId == userId
                              ? "chatBox__main-sms__box-text1"
                              : "chatBox__main-sms__box-text"
                          }
                        >
                          <p>{item?.msg}</p>
                        </div>
                        {item?.userId == userId && (
                          <img
                            src={item?.user?.profilePicture}
                            alt="profile"
                            width={"28px"}
                            height={"28px"}
                            style={{
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </div>
                      <p
                        className={
                          item?.userId == userId
                            ? "chatBox__main-sms__box-date1"
                            : "chatBox__main-sms__box-date"
                        }
                      >
                        Today ,{formatTime(item?.createdAt)}
                      </p>
                    </div>
                  </div>
                )}
                {item?.activityMsg !== null && (
                  <div>
                    <p
                      className="text-call"
                      onClick={() => handleClickCall(item?.msgUrl)}
                    >
                      <span className="text-call-name">
                        {" "}
                        {item?.user?.fullname}{" "}
                      </span>{" "}
                      started
                      <span className="text-call-name">
                        {" "}
                        {item?.activityMsg}{" "}
                      </span>{" "}
                      {/* at {formatTime(item?.createdAt)} */}
                    </p>
                  </div>
                )}
              </>
            </>
          ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="chatBox__main-send">
        <input
          type="text"
          placeholder="Type here..."
          onChange={(e) => setmsg(e.target.value)}
          value={msgs}
          onKeyDown={handleKeyDown}
        />
        <button onClick={SendMsg}>
          {" "}
          <img src="/Images/Projects/send.svg" alt="/" />{" "}
        </button>
      </div>
      <UploadDocument show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default Chatbox;
