import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import "./ContractEditor.css";
import * as React from "react";
import { Editor } from "primereact/editor";
import Form from "react-bootstrap/Form";
import { useState, useEffect, useRef } from "react";
import TextEditor from "../../TextEditor/TextEditor";
import Contract from "../Contract/Contract";
import { useDispatch, useSelector } from "react-redux";
import { SuccessToast, ErrorToast } from "../../toast/Toast";
import { API_URL } from "../../../services/client";
import api from "../../../services/apiInterceptor";
import ScreenLoader from "../../../Components/loader/ScreenLoader";
import AddContract from "../AddContract/AddContract";
import TestingEditior from "../../../Pages/TestingEditior";
import axios from "axios";

export default function ContractEditor(props) {
  const dispatch = useDispatch();
  const ContractName = useSelector(
    (state) => state?.addcontract?.contract.name
  );
  console.log("this is the uodates props", props);

  const fileInputRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowpreview, setModalShowPreview] = useState(false);
  const [inputEditor, setInputEditor] = useState(false);

  const [imageSrc, setImageSrc] = useState(null);
  const [userId, setUserId] = useState();
  const [promptText, setPromptText] = useState("");

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handlePreviewContract = () => {
    SuccessToast("Contract Details Added Successfully");
    setModalShowPreview(true);
    props.onHide();
  };
  const handleEditName = () => {
    setModalShow(true);
    props.onHide();
  };

  useEffect(() => {
    const userid = localStorage.getItem("_id");
    setUserId(userid);
    console.log("user id ", userId);
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        "application/pdf", // .pdf
        "image/png",
        "image/jpeg",
        "application/msword", //.doc
        // "application/x-edge-pdf"
      ];
      console.log("Detected File Type:", file.type);
      if (!allowedTypes.includes(file.type)) {
        console.error(
          "File type not supported. Please upload a Word document, PNG, JPEG, or PDF."
        );
        ErrorToast(
          "File type not supported. Please upload a Word document, PNG, JPEG, or PDF."
        );
        return;
      }
      setLoader(true);

      try {
        const formData = new FormData();
        formData.append("file", file);

        console.log("FILE Name:", file.name);

        const res = await api.post(
          `${API_URL}/contract/uploadContractFile/${userId}`,
          formData
        );

        if (res.status === 200) {
          setLoader(false);
          console.log("Contract Response", res);
          console.log("Contract Uploaded");
          setImageSrc(res?.data?.text);
          console.log(res?.data?.text, "this is the contract url");
          SuccessToast("Contract Uploaded Successfully");
        }
      } catch (error) {
        setLoader(false);
        console.error("Error:", error);
      }
    }
  };

  async function ChatAi() {
    try {
      setLoader(true);
      console.log(promptText);
      const res = await axios.post(`${API_URL}/api/chat`, {
        prompt: promptText,
      });
      setLoader(false);
      setImageSrc(res?.data?.message?.content);
      console.log(res, "this is the response");
    } catch (error) {
      console.log("this is error");
      ErrorToast(error);
    }
  }
  console.log("hello img", imageSrc);

  return (
    <>
      {loader && <ScreenLoader />}

      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="contract-editor-modal"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="add-project__header add-contract-header"
          >
            <div className="contract-editor-header">
              <p className="editor-title">{ContractName}</p>
              <img
                src="/Images/Contract/edit-icon.svg"
                alt="edit-icon"
                // onClick={() => setModalShow(true)}
                onClick={handleEditName}
              />
            </div>
            <AddContract
              show={modalShow}
              onHide={() => setModalShow(false)}
              showpreview={true}
            />
            <div className="add-project__close add-contract-close">
              <img
                src="/Images/Projects/close.svg"
                alt="close-icon"
                onClick={props.onHide}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "30px", paddingTop: "0px" }}>
          <div>
            <div
              className="btn-upload-contract"
              // onClick={() => setModalShow(true)}
              onClick={handleUploadClick}
            >
              Upload Contract
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept=".doc,.docx,.pdf,.png,.jpg,.jpeg"
              />
            </div>

            {/* <div className="upload-option" onClick={handleUploadClick}>
              <img
                src="/Images/Contract/device.svg"
                alt="device"
                className="upload-img"
              />
              <p className="option-name">From device</p>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div> */}

            <Form.Group>
              {/* <Editor
                id="description"
                //   value={discussion}
                //   onTextChange={(e) => setDiscussion(e.htmlValue)}
                style={{ height: "233px" }}
                className="editor__text"
                placeholder="Type here"
                // headerTemplate={header}
              /> */}

              {/* <Editor
                headerTemplate={header}
                style={{ height: "320px" }}
                value={text2}
                placeholder="Type here"
                // modules={toolbarOptions}
                onTextChange={(e) => setText2(e.htmlValue)}
              /> */}
            </Form.Group>
            {/* <TextEditor imgcontent={imageSrc && `<p>${imageSrc}</p>`} /> */}
            <TestingEditior imgcontent={imageSrc && `<p>${imageSrc}</p>`} />

            <div
              onClick={() => {
                setInputEditor(!inputEditor);
              }}
              className="chatgpt-div"
            >
              <div className="chatglt-chld">
                <img
                  alt=""
                  style={{ paddingRight: "8px" }}
                  src="/Images/Subscription/chatgpt.svg"
                />
                <p>Write contract with ChatGPT</p>
                {inputEditor ? (
                  <img
                    alt=""
                    style={{ paddingLeft: "8px" }}
                    src="/Images/Subscription/uparrow.svg"
                  />
                ) : (
                  <img
                    alt=""
                    style={{ paddingLeft: "8px" }}
                    src="/Images/Subscription/arrowDown.svg"
                  />
                )}
              </div>
            </div>
            {inputEditor && (
              <div className="chat-input-box-area">
                {/* <input
                  onChange={(e) => {
                    setPromptText(e.target.value);
                  }}
                  className="input-app"
                  placeholder="Enter promt..."
                /> */}
                <textarea
                  type="text"
                  placeholder="Enter promt..."
                  className="prompt-textarea"
                  onChange={(e) => {
                    setPromptText(e.target.value);
                  }}
                  // rows={8}
                  // cols={30}
                />
                {/* <img
                  alt=""
                  onClick={() => {
                    ChatAi();
                  }}
                  className="main-img-send"
                  src="/Images/Subscription/send.svg"
                /> */}
                <Button
                  className="btn-submit-txt"
                  // onClick={() => setModalShow(true)}
                  onClick={() => {
                    ChatAi();
                  }}
                >
                  Submit
                </Button>
              </div>
            )}

            <div className="btn-preview-div">
              <Button
                className="btn-preview"
                // onClick={() => setModalShow(true)}
                onClick={handlePreviewContract}
              >
                Preview Contract
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Contract
        show={modalShowpreview}
        setModalShow={props.setModalShow}
        onHide={() => setModalShowPreview(false)}
      />
    </>
  );
}
