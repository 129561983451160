import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import { useRef, useState,useEffect } from "react";
import { SuccessToast, ErrorToast } from "../../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../services/client";
import api from "../../../services/apiInterceptor.js";
import { useParams } from "react-router-dom";
import "./uploadDocument.css";
import * as React from "react";
import { uploadProject } from "../../../services/redux/middleware/Project/project.js";
import { viewProjectActivities } from "../../../services/redux/middleware/Project/project.js";
import ScreenLoader from "../../loader/ScreenLoader.js";

export default function UploadDocument(props) {
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(null);
  const fileInputRef = useRef(null);
  const { projectid } = useParams();
  const [userId, setUserId] = useState();
  const [loader,setLoader] =useState(false);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("Selected Project Document file:", file);

    if (file) {
      setLoader(true);

      try {
        const formData = new FormData();
        formData.append("file", file);
        const res = await api.post(`${API_URL}/upload/uploadimage`, formData);
        const data = {
          id: projectid,
          image_url: res?.data?.data,
          userId: userId,
          activity: "Project Document",
        };
        if (res.status === 200) {
          setLoader(false);
          console.log("Project Doc Response", res);
          console.log("Document Uploaded");
          setImageSrc(res?.data?.data);
          dispatch(uploadProject(data));
          console.log(res?.data?.data, "this is the Project doc URL");
          SuccessToast("Project Doc Uploaded Successfully");
          dispatch(viewProjectActivities(projectid));

          props.onHide();
        }
        else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };



  React.useEffect(() => {
    const userId = localStorage.getItem("_id");
    setUserId(userId);
  }, []);
  return (
    <>
    {loader && <ScreenLoader/>}
      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-project-modal"
      >
        <Modal.Header
          style={{ padding: "70px", paddingBottom: "0px", paddingTop: "60px" }}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="add-project__header"
            style={{
              marginBottom: "34px",
            }}
          >
            <div
              className="add-project__main-header"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h6 style={{ margin: "0px" }}>Upload project docs</h6>
              {/* <p>Fill the details below to add project</p> */}
            </div>
            <div className="add-project__close">
              <img
                src="/Images/Projects/close.svg"
                alt="close-icon"
                onClick={props.onHide}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-project-body">
            <div className="Upload__project-file">
              <label
                className="Upload__project-file-phone"
                htmlFor="file-input"
              >
                <input
                  type="file"
                  id="file-input"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <span>From device</span>
              </label>

              <label className="Upload__project-file-phone1">
                <input
                  type="file"
                  id="file-input"
                  style={{ display: "none" }}
                />
                <span>Google Drive</span>
              </label>
              {/* <label className="Upload__project-file-phone2">
                <input
                  type="file"
                  id="file-input"
                  style={{ display: "none" }}
                />
                <span>One Drive</span>
              </label> */}
              <label className="Upload__project-file-phone3">
                <input
                  type="file"
                  id="file-input"
                  style={{ display: "none" }}
                />
                <span>Drop Box</span>
              </label>
            </div>
            <div className="upload-project__main-btn">
              <Button
                className="upload-project__btn"
                onClick={props.onHide}
                style={{ marginBottom: "40px" }}
              >
                Save docs
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
