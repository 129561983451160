import React, { useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "./Dashboard.css";
import ContractTable from "../../Components/Tables/Contracts/ContractTable";
import Dropdown from "react-bootstrap/Dropdown";
import ContractProjectTable from "../../Components/Tables/ContractProject/ContractProjectTable";
import CreateContract from "../../Components/Cards/CreateContract/CreateContract";
import NoProjectCard from "../../Components/Cards/NoProject/NoProjectCard";
import Header from "../../Components/Header/Header";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getContract } from "../../services/redux/middleware/getContract";
import { useNavigate } from "react-router-dom";
import ScreenLoader from "../../Components/loader/ScreenLoader";
import { getfourProjects } from "../../services/redux/middleware/Project/project";
import { getTotalCount } from "../../services/redux/middleware/Project/project";
import AddContract from "../../Components/Modals/AddContract/AddContract";
import Button from "@mui/material/Button";
import { LatestProjContract } from "../../services/redux/middleware/Project/project";
import { ErrorToast } from "../../Components/toast/Toast";
import AddProject from "../../Components/Modals/AddProject/AddProject";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [showContract, setShowContract] = useState(true);
  const [noContract, setShowNoContract] = useState(true);
  const [noproject, setShowNoProject] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const ContractData = useSelector(
    (state) => state?.getContract?.contract?.data
  );
  console.log("Contract Data", ContractData);

  const FourProjectData = useSelector(
    (state) => state?.getfourProject?.myFourProjects?.data
  );
  console.log("My Own Projects", FourProjectData);

  const [userRole, setRole] = useState();
  const [UserID, setUserID] = useState("");

  useEffect(() => {
    const userrole = localStorage.getItem("role");
    setRole(userrole);
  }, [userRole]);

  useEffect(() => {
    const userid = localStorage.getItem("_id");
    console.log("user id ", userid);
    setUserID(userid);
    dispatch(getTotalCount(userid));

    dispatch(getContract(userid));
    dispatch(getfourProjects(userid));
    dispatch(LatestProjContract(userid));
  }, []);
  const LatestContract = useSelector(
    (state) => state?.getLatestProjCon?.myData?.data?.contract
  );
  console.log("MY LATEST CONTRACT ", LatestContract);

  async function Addcontract() {
    if (LatestContract <= 0) {
      ErrorToast("No Contract Found");
    } else {
      setModalShow(true);
    }
  }
  const LatestProject = useSelector(
    (state) => state?.getLatestProjCon?.myData?.data?.projects
  );
  console.log("MY LATEST PROJECT ", LatestProject);

  async function AddProj() {
    if (LatestProject <= 0) {
      ErrorToast("NO PROJECT FOUND");
    } else {
      setModalShow(true);
    }
  }
  const totalcount = useSelector(
    (state) => state?.getTotalcount?.myTotalCount?.data
  );
  console.log("My total count", totalcount);

  const userLoading = useSelector((state) => state?.getTotalcount);

  return (
    <>
      <Header headername={"Dashboard"} />
      {loader && <ScreenLoader />}
      {userLoading.loading && <ScreenLoader />}

      {userRole === "organization" ? (
        <div className="org-card-r1">
          <div className="org-card-inner">
            <div className="org-lhs-rhs">
              <div>
                <p className="total-p-txt">Total projects</p>
                <p className="total-value-p">{totalcount?.totalProjects}</p>
                <p className="total-plus-p"> + 3 projects</p>
              </div>
              <div>
                <img
                  src="/Images/Dashboard/arrow-down-card.svg"
                  alt="arrow-up"
                />
              </div>
            </div>
          </div>

          <div className="org-card-inner2">
            <div className="org-lhs-rhs">
              <div>
                <p className="total-p-txt">Total clients</p>
                <p className="total-value-p">{totalcount?.totalClients}</p>

                <p className="total-minus-p">- 1 client</p>
              </div>
              <div>
                <img src="/Images/Dashboard/arrow-drop-red.svg" alt="arrow" />
              </div>
            </div>
          </div>

          <div className="org-card-inner">
            <div className="org-lhs-rhs">
              <div>
                <p className="total-p-txt">Total contracts</p>
                <p className="total-value-p">{totalcount?.totalContracts}</p>

                <p className="total-plus-p">+ 18 contracts</p>
              </div>
              <div>
                <img
                  src="/Images/Dashboard/arrow-down-card.svg"
                  alt="arrow-up"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="Dash-body">
        {ContractData?.contracts?.length > 0 ? (
          <div className="contract-contain">
            <div className="contract-r1">
              <p className="contract-head">Contracts</p>
              <div className="view-add-btn">
                <div
                  onClick={() => navigate("/AllContract")}
                  className="view-all-btn-div"
                >
                  <p className="view-all-contain">
                    View all{" "}
                    <img
                      src="/Images/Dashboard/chevron-right.svg"
                      alt="arrow-icon"
                    />
                  </p>
                </div>

                <div className="Add-con-btn-div new-add-btn">
                  <Button
                    className="add-btn-contract new-add-btn"
                    onClick={() => Addcontract()}
                  >
                    Add Contract
                  </Button>
                  <AddContract
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    setModalShow={setModalShow}
                    showpreview={true}
                  />
                </div>
              </div>
              {/* <div className="drop-view-div">
                <Dropdown className="drop-view-main">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-view"
                  >
                    View all
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </div>

            <ContractTable />
          </div>
        ) : (
          <div className="create-contract-div">
            <CreateContract />
          </div>
        )}

        {/* {!noContract ? (
          <div className="create-contract-div">
            <CreateContract />
          </div>
        ) : (
          <div className="contract-contain">
            <div className="contract-r1">
              <p className="contract-head">Contracts</p>
              <div className="drop-view-div">
                <Dropdown className="drop-view-main">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-view"
                  >
                    View all
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <ContractTable />
          </div>
        )} */}

        {FourProjectData?.projects?.length > 0 ? (
          <div className="contract-project-contain">
            <div className="contract-r1">
              <p className="contract-head">Projects</p>
              <div className="drop-view-div view-add-btn">
                {/* <Dropdown className="drop-view-main">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-view"
                  >
                    View all
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}

                <div onClick={() => navigate("/ProjectsTable")}>
                  <p className="view-all-contain">
                    View all{" "}
                    <img
                      src="/Images/Dashboard/chevron-right.svg"
                      alt="arrow-icon"
                    />
                  </p>
                </div>
                {userRole == "organization" ? (
                  <div className="projecttable__main-headingbtn">
                    <button onClick={() => AddProj()}>Add Projects</button>
                  </div>
                ) : null}
              </div>
            </div>
            <ContractProjectTable />
          </div>
        ) : (
          <div className="noproject-div">
            <NoProjectCard />
          </div>
        )}
      </div>
    </>
  );
}
