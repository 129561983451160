import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import * as React from "react";
import { useState, useEffect } from "react";
import UploadSign from "../UploadSign/UploadSign";
import dayjs from "dayjs";
import { styled, useTheme } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
// import { savecontract } from "../../../services/redux/middleware/saveContract";
import { savecontract } from "../../../services/redux/middleware/contract";
import ScreenLoader from "../../../Components/loader/ScreenLoader";
import { useNavigate } from "react-router-dom";
import { SuccessToast, ErrorToast } from "../../toast/Toast";
import Parser from "html-react-parser";
import { viewcontract } from "../../../services/redux/middleware/contract";
import EditContractName from "./EditContractNameType";
import { getAllContract } from "../../../services/redux/middleware/getAllContract";
import { editcontractSignDate } from "../../../services/redux/middleware/contract";
import { getContract } from "../../../services/redux/middleware/getContract";

// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function EditContract(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [UserID, setUserID] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [uploadsign, setUploadSign] = useState(false);
  const [formattedContent, setFormattedContent] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const ContractPreviousData = useSelector(
    (state) => state?.getviewcontract?.viewContract?.data
  );
  console.log(
    "Contract Previous Data from edit contract",
    ContractPreviousData
  );

  const ContractPreviouContext = useSelector(
    (state) => state?.getviewcontract?.viewContract?.data?.contractText
  );

  const ContractSign = useSelector((state) => state?.addsign.contractsign);
  console.log("Contract Signature is", ContractPreviouContext);

  useEffect(() => {
    console.log("id is", props.ContractID);
    dispatch(viewcontract(props.ContractID));
    console.log("viewing");
  }, [props.ContractID]);

  useEffect(() => {
    const userid = localStorage.getItem("_id");
    console.log("user id ", userid);
    setUserID(userid);
  }, [UserID]);

  console.log("format", formattedContent);

  const handleEditName = () => {
    setModalShow(true);
    // props.onHide();
  };

  const ContractName = useSelector(
    (state) => state?.addcontract?.contract.name
  );
  console.log("Contract Name ", ContractName);

  const ContractType = useSelector(
    (state) => state?.addcontract?.contract.type
  );

  const ContractContent = useSelector(
    (state) => state?.addcontracteditor?.contracteditorcontent
  );
  console.log("Contract Content ", ContractContent);

  async function SaveContract() {
    setLoader(true);
    try {
      const data = {
        id: props.ContractID,
        userId: UserID,
        date: ContractPreviousData?.Date
          ? ContractPreviousData?.Date
          : startDate,
        signature: ContractPreviousData.signatureImage
          ? ContractPreviousData.signatureImage
          : uploadsign,
      };

      dispatch(editcontractSignDate(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          console.log("Contract Edited res", res?.payload?.data);
          // dispatch(setContract({ name:"",type:"" }));
          // dispatch(setContractSign(""));
          dispatch(viewcontract(props.ContractID));
          const dataall = {
            id: UserID,
            page: 1,
          };
          dispatch(getAllContract(dataall));
          dispatch(getContract(UserID));
          SuccessToast("Contract EDITED Successfully");

          // navigate("/Dashboard")

          props.onHide();
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }
  return (
    <>
      {loader && <ScreenLoader />}
      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="contract-modal"
      >
        <Modal.Header
          style={{ padding: "70px", paddingBottom: "0px", paddingTop: "60px" }}
        >
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="contract-title-top"
          >
            <p className="contract-title">
              {ContractPreviousData?.contractName}
            </p>
            {ContractPreviousData?.userId === parseInt(UserID) && (
              <img
                src="/Images/Contract/edit-icon.svg"
                alt="edit-icon"
                className="edit-icon-name"
                onClick={handleEditName}
                // onClick={() => setModalShow(true)}
              />
            )}

            <EditContractName
              show={modalShow}
              onHide={() => setModalShow(false)}
              showpreview={false}
              ContractID={props.ContractID}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "0px", paddingTop: "50px", paddingBottom: "30px" }}
        >
          <div>
            <div
              style={{
                width: "100%",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <div
                dangerouslySetInnerHTML={{ __html: ContractPreviouContext }}
              />
              {/* <div style={{ width: "100%" }}>
                {Parser(ContractPreviouContext)}
              </div> */}
            </div>
            {/* <div style={{width:"100%"}} dangerouslySetInnerHTML={{ __html: ContractContent }} /> */}
            {/* <img
              src="/Images/Contract/contract-img.svg"
              alt=""
              height={"100%"}
              width={"100%"}
            /> */}
            <div className="contract-btm">
              <div className="sign-date-contain align-items-end">
                <div className="sign-inner-contain">
                  <div className="sign-edit">
                    {ContractPreviousData?.signatureImage ? (
                      <img
                        src={ContractPreviousData?.signatureImage}
                        alt="signature"
                        width={"94px"}
                        height={"63px"}
                      />
                    ) : (
                      // {  ContractSign &&
                      //     <img

                      //       src={ContractSign}

                      //       alt="Signature"
                      //       width={"94px"}
                      //       height={"63px"}
                      //     />
                      // }

                      <>
                        {ContractSign ? (
                          <>
                            <img
                              src={ContractSign}
                              alt="Signature"
                              width={"94px"}
                              height={"63px"}
                            />
                            {/* <img src="https://drive.google.com/uc?export=view&id=1Ml67WAsVbl06WBEINR_hPvLHFFIPGdyJ" alt="drive image"/> */}
                          </>
                        ) : (
                          <>
                            <p
                              className="Upload-sign-txt"
                              onClick={() => setUploadSign(true)}
                            >
                              Upload Signature
                            </p>
                            <UploadSign
                              show={uploadsign}
                              onHide={() => setUploadSign(false)}
                            />

                            <img
                              src="/Images/Contract/edit-icon.svg"
                              alt="edit"
                              className="  edit-icon-name"
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <p className="sign-txt">Signature</p>
                </div>

                {/* <div>

                   <div className="d-flex">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Type Date"
                          placeholder="hh"
                          sx={{
                            "& label": {
                              textDecoration: "underline",
                              color: "#0a1126",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "500",
                              paddingLeft: "0px",
                            },
                            ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                // paddingLeft:"0px"
                              },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <img src="/Images/Contract/edit-icon.svg" alt="edit"  className="pt-2"/>
                  </div>

                  <p className="sign-txt">Date</p>
                </div> */}

                {/* <div className="sign-inner-contain">
                  {ContractPreviousData?.Date ? (
                    ContractPreviousData?.Date
                  ) : (
                    <>
                      <div className="d-flex pb-date">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          autoFocus={false}
                          className="custom-datepicker"
                        />
                        <img src="/Images/Contract/edit-icon.svg" alt="edit" />
                      </div>
                    </>
                  )}
                  <p className="sign-txt">Date</p>
                </div> */}

                <div className="sign-inner-contain">
                  {ContractPreviousData?.Date ? (
                    <>
                      {new Date(ContractPreviousData.Date).toLocaleDateString(
                        "en-US",
                        {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        }
                      )}
                    </>
                  ) : (
                    <>
                      <div className="d-flex pb-date">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          autoFocus={false}
                          className="custom-datepicker"
                          placeholderText="Type Date"
                        />

                        <img src="/Images/Contract/edit-icon.svg" alt="edit" />
                      </div>
                    </>
                  )}
                  <p className="sign-txt">Date</p>
                </div>
              </div>

              {/* <div
                className={
                  ContractPreviousData?.userId === parseInt(UserID)
                    ? " "
                    : "margin-btn-top"
                }
              >
                {ContractPreviousData?.userId === parseInt(UserID) && (
                  <Button
                    className={"save-contract-btn"}
                    onClick={SaveContract}
                  >
                    Save contract
                  </Button>
                )}
              </div> */}
              <div>
                <Button className={"save-contract-btn"} onClick={SaveContract}>
                  Save contract
                </Button>
              </div>
            </div>
            <div className="contract-footer">
              <img
                src="/Images/Contract/pocketfiler - logo.svg"
                alt="logo"
                className="contract-logo"
              />
              <p className="www-txt">www.pocketfiller.com</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
