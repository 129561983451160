import React from "react";
import "./ChatboxDispute.css";
import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ScreenLoader from "../../loader/ScreenLoader";
import UploadDocument from "../../Modals/UploadDocuments/UploadDocument";
import {
  ChatCall,
  chatSendMsg,
  getChatHistory,
} from "../../../services/redux/middleware/Project/project";
import {
  chatSendMsgDispute,
  getChatHistoryDispute,
} from "../../../services/redux/middleware/Dispute/dispute";
import { SuccessToast, ErrorToast } from "../../toast/Toast";
import { socket } from "../../../services/socket";
import MsgNotifySound from "../../../assets/audio/message-notification-190034.mp3"



const ChatboxDispute = ({ disputing }) => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState();
  const [msgs, setmsg] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { projectid } = useParams();
  const [modalShow, setModalShow] = useState(false);

  let audio = new Audio(MsgNotifySound);


  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault(); // Prevent default form submission behavior
      SendMsg();
    }
  };

  useEffect(() => {
    const userid = localStorage.getItem("_id");
    console.log("user id ", userid);
    setUserId(userid);
  }, [userId]);

  useEffect(() => {
    console.log("id is", projectid);
    dispatch(getChatHistoryDispute(projectid));
  }, []);


  const GetMsgDisputeHistory = () => {
    audio.play();
    dispatch(getChatHistoryDispute(projectid));

  };

  useEffect(() => {
    const userid = localStorage.getItem("_id");
    socket.on("connect", () => {
      // audio.play();
      console.log("socket is connecting");
      socket.emit("join", userid);
    });
    if (userid && projectid) {
      socket.on("newMessengerHistory", GetMsgDisputeHistory);

      socket.on("disconnect", (reason) => {});
      return () => {
        if (userid && projectid) {
          socket.off("newMessengerHistory", GetMsgDisputeHistory);
        }
        socket.off("connect", (reason) => {});
        socket.off("disconnect", (reason) => {});
      };
    }
  }, [ ]);

  async function SendMsg() {
    setLoader(true);
    try {
      const data = {
        projectId: projectid,
        userId: userId,
        msg: msgs,
      };

      dispatch(chatSendMsgDispute(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          console.log("Msg sent success", res?.payload?.data);
          // SuccessToast("Message sent successfully");
          setmsg("");
          dispatch(getChatHistoryDispute(projectid));
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const formatTime = (createdAt) => {
    if (!createdAt) return ""; // handle case where createdAt is undefined or null

    const date = new Date(createdAt);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be converted to 12

    // Padding minutes with zero if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;
  };
  const myCHat = useSelector(
    (state) => state?.getChatHistoryDispute?.myDisputeChatHistory?.data
  );


  return (
    <div className="chatBox__main ">
      <div className="chatBox__main-header">
        <div className="chatBox__main-header_left">
          <div className="chatBox__main-header_left-img">
            <img src="/Images/Projects/jennifer.svg" alt="/" />
          </div>
          <div className="chatBox__main-header_left-txt">
            <h2>Jennifer Lee</h2>
            <img
              src="/Images/Projects/recording-01.svg"
              alt="recording"
              className="record-img"
            />
            <p className="every-txt">
              Everything will be recorded at PocketFiler{" "}
            </p>
          </div>
        </div>
        <div className="chatBox__main-header_right">
          <button
            className="ProjectActivities__top-box_header-btn2"
            onClick={() => setModalShow(true)}
          >
            Upload documents
          </button>
        </div>
      </div>
      <div className="chatBox__border"></div>

      <div className="msgs-container">
        {myCHat &&
          myCHat.map((item, index) => (
            <>
              <div
                className={
                  item?.userId == userId
                    ? "chatBox__main-sms1"
                    : "chatBox__main-sms"
                }
              >
                <div
                  className={
                    item?.userId == userId
                      ? "chatBox__main-sms__box1"
                      : "chatBox__main-sms__box"
                  }
                >
                  <div className="chatBox__main-sms__box-img">
                    {item?.userId != userId && (
                      <img
                        src={item?.user?.profilePicture}
                        alt="profile"
                        width={"28px"}
                        height={"28px"}
                        style={{
                          borderRadius: "50%",
                        }}
                      />
                    )}
                    <div
                      className={
                        item?.userId == userId
                          ? "chatBox__main-sms__box-text1"
                          : "chatBox__main-sms__box-text"
                      }
                    >
                      <p>{item?.msg}</p>
                    </div>
                    {item?.userId == userId && (
                      <img
                        src={item?.user?.profilePicture}
                        alt="profile"
                        width={"28px"}
                        height={"28px"}
                        style={{
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </div>
                  <p
                    className={
                      item?.userId == userId
                        ? "chatBox__main-sms__box-date1"
                        : "chatBox__main-sms__box-date"
                    }
                  >
                    Today ,{formatTime(item?.createdAt)}
                  </p>
                </div>
              </div>
            </>
          ))}
      </div>

      <div className="chatBox__main-send">
        <input
          type="text"
          placeholder="Type here..."
          onChange={(e) => setmsg(e.target.value)}
          value={msgs}
          onKeyDown={handleKeyDown}
        />
        <button onClick={SendMsg}>
          {" "}
          <img src="/Images/Projects/send.svg" alt="/" />{" "}
        </button>
      </div>
      <UploadDocument show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default ChatboxDispute;
