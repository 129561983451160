import "./sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import * as React from "react";
import { useDispatch } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Avatar from "@mui/material/Avatar";

import {
  // StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Menu } from "@mui/material";

import { useMediaQuery } from "react-responsive";
// import { Logout, X } from "@mui/icons-material";
// import { useDispatch, useSelector } from "react-redux";
// import { getProfileImage } from "../../services/redux/middleware/signin";

const drawerWidth = 310;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    height: "100%",
    overflowX: "hidden",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      fontFamily: "ClashGrotesk",
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,

    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "transparent",
  boxShadow: "none",
  paddingTop: "40px",

  borderBottom: "none",
  zIndex: "2",
}));

const AvatarTopDiv = styled("div")(({ theme }) => ({
  display: "none",
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.up("md")]: {
    width: "100%",
    minWidth: 768,
  },
  [theme.breakpoints.down("sm")]: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    right: "auto",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end",

    gap: theme.spacing(2),
  },
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "end",
  padding: "26px 24px ",

  paddingTop: "0px",
  paddingBottom: 0,

  borderBottom: "none",
  zIndex: "2",
}));

const themeMui = createTheme({
  typography: {
    fontFamily: '"ClashGrotesk"',
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
});

export default function Sidebar({ children, showSidebar, PageName }) {
  const dispatch = useDispatch();
  const [userRoles, setUserRoles] = useState();

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setUserRoles(userRole);
  }, [userRoles]);

  //   const userDataInfo = useSelector(
  //     (state) => state?.profileInfo?.userProfile?.data
  //   );

  // const [searchTerm, setSearchTerm] = useState("");

  // const handleChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };
  const theme = useTheme();
  const isMobile = useMediaQuery({
    query: "(max-width: 1064px)",
  });

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  const [selectedItem, setSelectedItem] = useState(null);

  
  const handleListItemClick = (event, index) => {
    console.log("clicked", index);
    setSelectedItem((prevIndex) => (prevIndex === index ? null : index));
  };

  // const handleListItemClick = (index) => {
  //   setSelectedItem(index);
  // };

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    console.log("drawer open");
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMobileOpen(!mobileOpen);
    console.log("drawer closed");
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const opens = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
    console.log("closing");
  };

  // const handleLogout = () => {
  //   handleClose();
  // };

  // const handleSignIn = () => {
  //   localStorage.clear();
  //   navigate("/");
  // };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const userMenuItems = [
    { text: "Dashboard", path: "/Dashboard", disabled: false },
    { text: "Associate", path: "/Clients", disabled: false },
    { text: "Associate Request", path: "/ClientReq", disabled: false },
    { text: "Help", path: "/HelpCenter" },
    { text: "Dispute", path: "/Disputes" },
  ];

  const organizationMenuItems = [
    { text: "Dashboard", path: "/Dashboard", disabled: false },
    { text: "Projects", path: "/ProjectsTable", disabled: false },
    { text: "Clients", path: "/Clients", disabled: false },
    { text: "Client Request", path: "/ClientReq", disabled: false },
    { text: "Help", path: "/HelpCenter" },
    { text: "Dispute", path: "/Disputes" },
  ];

  const userIcons = [
    "/Images/Dashboard/home-smile.svg",
    "/Images/Dashboard/users-01.svg",
    "/Images/Clients/arrow.svg",
    "/Images/Dashboard/headphones-02.svg",
    "/Images/Dashboard/dispute-icon.svg",
  ];

  const organizationIcons = [
    "/Images/Dashboard/home-smile.svg",
    "/Images/Dashboard/project-icon.svg",
    "/Images/Dashboard/users-01.svg",
    "/Images/Clients/arrow.svg",
    "/Images/Dashboard/headphones-02.svg",
    "/Images/Dashboard/dispute-icon.svg",
  ];

  const menuItems =
    userRoles === "user" ? userMenuItems : organizationMenuItems;
  const icons = userRoles === "user" ? userIcons : organizationIcons;

  // const menuItems = [
  //   { text: "Dashboard", path: "/Dashboard", disabled: false },
  //   { text: "Projects", path: "/ProjectsTable", disabled: false },
  //   { text: "Clients", path: "/Clients", disabled: false },
  //   { text: "ClientRequest", path: "/ClientReq", disabled: false },
  //   { text: "Help", path: "/HelpCenter" },
  //   { text: "Associate", path: "/Clients", disabled: false },
  // ];
  // const dashicons = [
  //   "/Images/Dashboard/home-smile.svg",
  //   "/Images/Dashboard/project-icon.svg",
  //   "/Images/Dashboard/users-01.svg",
  //   "/Images/Clients/arrow.svg",
  //   "/Images/Dashboard/headphones-02.svg",
  //   "/Images/Dashboard/client-icon.svg",
  // ];

  // let users = JSON.parse(localStorage.getItem("user"));

  // let filteredMenuItems = [];

  // if (userRoles === "user") {
  //   const allowedItems = ["Dashboard", "Associate", "Help", "ClientRequest"];

  //   filteredMenuItems = menuItems.filter((item) =>
  //     allowedItems.includes(item.text)
  //   );

  //   // Define the desired order
  //   const sortedAllowedItems = [
  //     "Dashboard",
  //     "Associate",
  //     "Help",
  //     "ClientRequest",
  //   ];

  //   // Sort the filtered items based on the desired order
  //   filteredMenuItems.sort(
  //     (a, b) =>
  //       sortedAllowedItems.indexOf(a.text) - sortedAllowedItems.indexOf(b.text)
  //   );
  // } else {
  //   filteredMenuItems = menuItems.filter((item) => item.text !== "Associate");
  // }

  // for connect wallet
  //   const [wallet, SetWallet] = useState(false);
  //   const { account, chainId } = useWeb3React();
  //   const userImage = useSelector(
  //     (state) => state?.profileImage?.profileImage?.data?.image
  //   );
  //   const { logout } = useAuth();
  // const connectorId =
  //   typeof window !== "undefined" ? localStorage.getItem("connectorId") : null;
  //   async function handleConnect() {
  //     if (!account) {
  //       SetWallet(true);
  //     } else {
  //       await logout(connectorId);
  //       localStorage.clear();
  //       navigate("/login");
  //     }
  //   }

  async function Logout() {
    localStorage.clear();

    navigate("/");
  }
  //   useEffect(() => {
  //     const userId = localStorage.getItem("_id");
  //     dispatch(getProfileImage(userId));
  //   }, []);

  return (
    <>
      <ThemeProvider theme={themeMui}>
        {showSidebar && (
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
              <Toolbar style={{ position: "relative", zIndex: "2" }}>
                {" "}
                <IconButton
                  color="#0000"
                  aria-label="open drawer"
                  onClick={isMobile ? handleDrawerToggle : handleDrawerOpen}
                  edge="start"
                  className="menu-icon-btn"
                  sx={{
                    mr: 2,
                    ...(open && { display: "none" }),
                    position: "absolute",
                    zIndex: 999,
                    // mt: 1,
                    // mb: isMobile768 ? "21px" : "0px"  || isMobile500 ? "12px",
                  }}
                >
                  <MenuIcon
                    style={{
                      position: "relative",
                      zIndex: 999,
                      color: "#000",
                      width: "30px",
                      height: "30px",
                      paddingRight: "5px",
                      // paddingTop:"8px"
                    }}
                    color={"black"}
                  />
                </IconButton>
                <div className="d-flex flex-column d-none">
                  <AvatarTopDiv
                    style={{ boxShadow: "none", zIndex: 1, display: "none" }}
                  >
                    <div
                      id="basic-button"
                      className="Avatar-top-div"
                      aria-controls={opens ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={opens ? "true" : undefined}
                    >
                      <div>
                        <p
                          className={open ? "welcome-txt" : "welcome-txt-space"}
                        >
                          {PageName}
                        </p>
                      </div>
                      <div className="notify-search">
                        <div>
                          <div className="setting-notify">
                            <div className="nav__Right">
                              {/* <img src={Search} alt="Logo" /> */}
                              <Dropdown className="navbar__notification">
                                <Dropdown.Toggle className="navbar__notification__dropdown">
                                  <img
                                    src="/Images/Dashboard/notification.svg"
                                    alt="notification"
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                // style={{ background: "#F8F8F899", color: "red" }}
                                >
                                  <div className="notify-top">
                                    <h2 className="navbar__notification__dropdown__title">
                                      Today
                                    </h2>
                                    <p className="mark-read">Mark as read</p>
                                  </div>

                                  <div className="notify-cards">
                                    <Dropdown.Item href="">
                                      <div className="notify-cancel">
                                        <div>
                                          <img
                                            src="/Images/Dashboard/notigy-lock-alert.svg"
                                            alt="notify-lock"
                                          />
                                        </div>
                                        <div>
                                          <p className="Contract-cancel-head">
                                            Contract cancel
                                          </p>
                                          <p className="Cancel-des">
                                            Sed ut perspiciatis unde omnis iste
                                            natus error sit<br></br> voluptatem
                                            accusantium doloremque.
                                          </p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <div className="notify-cancel notify-pt">
                                        <div>
                                          <img
                                            src="/Images/Dashboard/notify-lock-icon.svg"
                                            alt="notify-lock"
                                          />
                                        </div>

                                        <div className="notify-bluedot-subtxt">
                                          <div>
                                            <p className="Contract-cancel-head">
                                              Password changed
                                            </p>

                                            <p className="Cancel-des">
                                              Your account password successfully
                                              updated.
                                            </p>
                                          </div>

                                          <div className="blue-dot"></div>
                                        </div>
                                      </div>
                                    </Dropdown.Item>

                                    <h2 className="navbar__notification__dropdown__title notify-day-pt">
                                      Yesterday
                                    </h2>

                                    <Dropdown.Item href="">
                                      <div className="notify-cancel">
                                        <div>
                                          <img
                                            src="/Images/Dashboard/notigy-lock-alert.svg"
                                            alt="notify-lock"
                                          />
                                        </div>
                                        <div>
                                          <p className="Contract-cancel-head">
                                            Contract cancel
                                          </p>
                                          <p className="Cancel-des">
                                            Sed ut perspiciatis unde omnis iste
                                            natus error sit<br></br> voluptatem
                                            accusantium doloremque.
                                          </p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <div className="notify-cancel notify-pt">
                                        <div>
                                          <img
                                            src="/Images/Dashboard/notify-lock-icon.svg"
                                            alt="notify-lock"
                                          />
                                        </div>

                                        <div className="notify-bluedot-subtxt">
                                          <div>
                                            <p className="Contract-cancel-head">
                                              Password changed
                                            </p>

                                            <p className="Cancel-des">
                                              Your account password successfully
                                              updated.
                                            </p>
                                          </div>

                                          <div className="blue-dot"></div>
                                        </div>
                                      </div>
                                    </Dropdown.Item>

                                    <Dropdown.Item href="">
                                      <div className="notify-cancel notify-pt">
                                        <div>
                                          <img
                                            src="/Images/Dashboard/notigy-lock-alert.svg"
                                            alt="notify-lock"
                                          />
                                        </div>
                                        <div>
                                          <p className="Contract-cancel-head">
                                            Contract cancel
                                          </p>
                                          <p className="Cancel-des">
                                            Sed ut perspiciatis unde omnis iste
                                            natus error sit<br></br> voluptatem
                                            accusantium doloremque.
                                          </p>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <div className="notify-cancel notify-pt">
                                        <div>
                                          <img
                                            src="/Images/Dashboard/notify-lock-icon.svg"
                                            alt="notify-lock"
                                          />
                                        </div>

                                        <div className="notify-bluedot-subtxt">
                                          <div>
                                            <p className="Contract-cancel-head">
                                              Password changed
                                            </p>

                                            <p className="Cancel-des">
                                              Your account password successfully
                                              updated.
                                            </p>
                                          </div>

                                          <div className="blue-dot"></div>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <Avatar
                            // onClick={() => navigate("/Profile")}
                            alt="Profile Picture"
                            src="/Images/Dashboard/profile.svg"
                            // src={
                            //   userImage
                            //     ? userImage
                            //     : "/Images/Dashboard/Profile-top.svg"
                            // }
                            // src={users?.profilePic}
                            sx={{
                              width: 60,
                              height: 60,
                            }}
                            className="avatar-img"
                          />
                          {/* {!userImage && (
                            <img
                              src="/Images/!.svg"
                              alt="Alert"
                              style={{
                                position: "absolute",
                                top: "9px",
                                right: 0,
                                transform: "translate(50%, -50%)",
                              }}
                            />
                          )} */}
                        </div>
                      </div>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={opens}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      ></Menu>
                    </div>
                  </AvatarTopDiv>
                </div>
                {/* <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography> */}
              </Toolbar>
            </AppBar>
            {!isMobile && (
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: " #fff",
                  },
                }}
                style={{ zIndex: 1, position: "relative" }}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,

                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
              >
                <div className="sidebar__top-div">
                  <div>
                    <DrawerHeader
                      sx={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <IconButton
                        onClick={handleDrawerClose}
                        style={{ color: "#000" }}
                        className="sidebar__icon"
                      >
                        {theme.direction === "ltr" ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </IconButton>
                    </DrawerHeader>

                    <img
                      src="/Images/Dashboard/pocketfiler - logo.svg"
                      // width={"30px"}
                      // height={"30px"}
                      className="web__logo "
                      alt="Logo"
                    />
                    <Divider />
                    <div className="sidebar__border"></div>
                    <List className="List-div">
                      {menuItems?.map(({ text, path, disabled }, index) => (
                        <ListItem
                          key={text}
                          disablePadding
                          divider={false}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "22.4px",
                          }}
                          className={
                            index === selectedItem ? "list-item-active" : ""
                          }
                        >
                          <ListItemButton
                            component={Link}
                            to={path}
                            onClick={(event) =>
                              handleListItemClick(event, index)
                            }
                            selected={index === selectedItem}
                            className={
                              index === selectedItem
                                ? "list-item-active"
                                : "list-item"
                            }
                            sx={{
                              "& .MuiListItemText-root": {
                                fontSize: "14px",
                              },
                              "& .MuiListItemText-primary": {
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#0a1126",
                                letterSpacing: "1px",
                              },

                              "&.Mui-selected": {
                                backgroundColor: "transparent",
                              },
                            }}
                          >
                            <ListItemIcon
                              minWidth={"24px"}
                              className="list-icons"
                              selected={index === selectedItem}
                              onClick={(event) =>
                                handleListItemClick(event, index)
                              }

                              sx={{
                                "& ..MuiListItemIcon-root": {
                                  minWidth: "24px",
                                },
                              }}
                            >
                              <img
                                src={icons[index]}
                                alt={`Icon ${index + 1}`}
                              />
                            </ListItemIcon>
                            <ListItemText
                              selected={index === selectedItem}
                              className={
                                index === selectedItem
                                  ? "list-item"
                                  : "list-item"
                              }
                              primary={text}
                            />
                            {/* {disabled && (
                          <img src="/Images/clock.svg" alt={`Disabled Image`} />
                        )}{" "} */}
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>

                    <List className="List-div" sx={{ paddingTop: "0px" }}>
                      <div
                        onClick={() => {
                          Logout();
                        }}
                        className="sidebar__logout  "
                      >
                        <img src="/Images/Dashboard/logout.svg" alt="/" />
                        <p>Logout</p>
                      </div>
                    </List>
                  </div>
                  <div>
                    <List>
                      <div className="sidebar__bottom">
                        <img src="/Images/Dashboard/illustartion.png" alt="/" />
                        <p>
                          Subscribe to <br /> unlimited contracts
                        </p>
                        <button
                          onClick={() => {
                            navigate("/Subscription");
                          }}
                        >
                          Upgrade
                        </button>
                      </div>
                    </List>
                  </div>
                </div>
              </Drawer>
            )}

            {isMobile && (
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: "#fff",
                  },
                }}
                sx={{
                  width: 250,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              >
                <DrawerHeader
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: "80px",
                    paddingBottom: "35px",
                  }}
                >
                  <img
                    src="/Images/Dashboard/pocketfiler - logo.svg"
                    // width={"30px"}
                    // height={"30px"}
                    className=" wweb__logo"
                    alt="Logo"
                  />
                  <IconButton
                    onClick={handleDrawerClose}
                    style={{ color: "#000" }}
                  >
                    {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                </DrawerHeader>

                <Divider />
                <div className="sidebar__border"></div>
                <List className="List-div">
                  {menuItems?.map(({ text, path, disabled }, index) => (
                    <ListItem
                      key={text}
                      disablePadding
                      className={
                        index === selectedItem ? "list-item" : "list-item"
                      }
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <ListItemButton
                        component={Link}
                        to={path}
                        onClick={(event) => handleListItemClick(event, index)}
                        selected={index === selectedItem}
                        className={
                          index === selectedItem
                            ? "list-item-active"
                            : "list-item"
                        }
                        sx={{
                          "& .MuiListItemText-root": {
                            fontSize: "14px",
                          },
                          "& .MuiListItemText-primary": {
                            fontSize: "14px",
                          },
                          // '&.Mui-selected': {
                          //   backgroundColor:"#166FBF0F",

                          // }
                        }}
                      >
                        <ListItemIcon
                          minWidth={"24px"}
                          className="list-icons"
                          selected={index === selectedItem}
                          sx={{
                            "& ..MuiListItemIcon-root": {
                              minWidth: "24px",
                            },
                          }}
                        >
                          <img src={icons[index]} alt={`Icon ${index + 1}`} />
                        </ListItemIcon>
                        <ListItemText
                          selected={index === selectedItem}
                          className={
                            index === selectedItem ? "list-item" : "list-item"
                          }
                          primary={text}
                        />
                        {/* {disabled && (
                          <img src="/Images/clock.svg" alt={`Disabled Image`} />
                        )}{" "} */}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                <Divider />

                <List className="List-div" sx={{ paddingTop: "0px" }}>
                  {/* <p className="items-main-heading">Other Menu</p> */}
                  <div
                    onClick={() => {
                      Logout();
                    }}
                    className=" sidebar-log-mb"
                  >
                    <img src="/Images/Dashboard/logout.svg" alt="/" />
                    <p>Logout</p>
                  </div>

                  {/* <div>
                    {filteredOtherMenuItems?.map(({ text, path }, index) => (
                      <ListItem
                        key={text}
                        disablePadding
                      >
                        <ListItemButton
                          component={Link}
                          to={path}
                          onClick={() => handleOtherListItemClick(index)}
                          selected={index === selectedotherItem}
                          className={
                            index === selectedotherItem
                              ? "list-item-active"
                              : "list-item"
                          }
                          sx={{
                            "& .MuiListItemText-root": {
                              fontSize: "14px",
                            },
                            "& .MuiListItemText-primary": {
                              fontSize: "14px",
                            },
                          }}
                        >
                          <ListItemIcon
                            className="list-icons"
                            sx={{
                              "& ..MuiListItemIcon-root": {
                                minWidth: "24px",
                              },
                            }}
                            minWidth={"24px"}
                          >
                            <img
                              src={otherdashicons[index]}
                              alt={`Icon ${index + 1}`}
                            />
                          </ListItemIcon>
                          <ListItemText
                            selected={index === selectedotherItem}
                            className={
                              index === selectedotherItem
                                ? "list-item"
                                : "list-item"
                            }
                            primary={text}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </div> */}
                </List>
                <List>
                  <div className="sidebar__bottom">
                    <img src="/Images/Dashboard/illustartion.png" alt="/" />
                    <p>Subscribe to unlimited contracts</p>
                    <button
                      onClick={() => {
                        navigate("/Subscription");
                      }}
                    >
                      Upgrade
                    </button>
                  </div>
                </List>
                {/* <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    Logout();
                  }}
                  className="Logout-div"
                >
                  <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                  <p className="Logout-txt">logout</p>
                </div> */}
              </Drawer>
            )}

            <Main
              open={open}
              sx={{
                backgroundColor: "var(--grey-0-primary-screen-color, #F9F9FC)",
                height: "100vh",

                // paddingLeft: isMobile ? "100px" : "50px",
                paddingLeft: isMobile ? "65px" : "50px",
              }}
            >
              {/* <DrawerHeader /> */}
              {children}
            </Main>
          </Box>
        )}
      </ThemeProvider>
      {/* <Web3Modal show={wallet} setModalShows={SetWallet} /> */}
    </>
  );
}
