import React, { useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "./profile.css";
import axios from "axios";
import AddClients from "../../Components/Modals/AddClients/AddClients";
import Header from "../../Components/Header/Header";
import { API_URL } from "../../services/client.js";
import api from "../../services/apiInterceptor.js";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getProfileImage,
} from "../../services/redux/middleware/signin.js";
import ScreenLoader from "../../Components/loader/ScreenLoader.js";
import { ErrorToast, SuccessToast } from "../../Components/toast/Toast.js";
import { Password } from "@mui/icons-material";




const Profile = () => {
  const dispatch = useDispatch();
  const [Userrole, setUserRole] = useState();
  const [ID, setID] = useState();

  const defaultprofile = "/Images/default-profile.png";
  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [UserName, setUserName] = useState("");
  const [OrgName, setOrgName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhNo, setPhNo] = useState("");
  const [password, setPassword] = useState("");
  const [imageSrc, setImageSrc] = useState(defaultprofile);
  const [showPassword, setShowPassword] = useState(false);
  const [userPasswordError, setUserPasswordError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const userData = useSelector((state) => state?.profile?.profile?.data);
  const userLoading = useSelector((state) => state?.profile);

  useEffect(() => {
    const userroles = localStorage.getItem("role");
    setUserRole(userroles);
  }, [Userrole]);

  useEffect(() => {
    const id = localStorage.getItem("_id");
    setID(id);
    dispatch(getProfile(id));
  }, [ID]);

  useEffect(() => {
    localStorage.setItem("profileupdate", userData?.profileUpdate);
    setUserName(userData?.fullname);
    setEmail(userData?.email);
    setPhNo(userData?.phoneNo);
    setPassword(userData?.password);
    if (userData?.profilePicture) {
      setImageSrc(userData?.profilePicture);
    } else {
      setImageSrc("/Images/default-profile.png");
    }
    if (userData?.role === "organization") {
      setOrgName(userData?.organizationName);
    } else {
      setOrgName("");
    }

    // setTelegram(userData?.telegramHandle);
    // setXhandle(userData?.xHandle);
  }, [userData]);

  const handlePhoneNo = (e) => {
    const value = e;
    // Remove any non-numeric characters
    const formattedValue = value.replace(/[^\d-]/g, "");
    setPhNo(formattedValue);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log(file,"tjis is file")

    if (file) {
      try {
        setLoader(true);
        const formData = new FormData();
        formData.append("file", file);
        console.log(formData)
        const res = await api.post(`${API_URL}/upload/uploadimage`, formData);

        if (res.status === 200) {
          console.log("profile Response", res);
          console.log("Profile Picture Uploaded");
          setLoader(false);

          setImageSrc(res?.data?.data);
          console.log(res?.data?.data, "this is the url");
          SuccessToast("Profile Uploaded Successfully");
        } else {
          ErrorToast(res.error);
          setLoader(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoader(false);
      }
    }
  };

  const validatePassword = (password) => {
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (password.length < 8) {
      ErrorToast(
        "Password must be 8 characters long with at least one special character."
      );

      return "Password must be exactly 8 characters long.";
    }
    if (!specialCharRegex.test(password)) {
      ErrorToast(
        "Password must be 8 characters long with at least one special character."
      );

      return "Password must contain at least one special character.";
    }
    return null;
  };

  const formValidation = () => {
    if (imageSrc === "/Images/Profile/profile-icon.svg") {
      ErrorToast("Please upload image ");
      return false;
    } else if (userData?.role === "organization" && !OrgName) {
      ErrorToast("Please enter your Organization");
      return false;
    } else if (!UserName) {
      ErrorToast("Please Enter your User Name");
      return false;
    } else if (!PhNo) {
      ErrorToast("Please enter your Phone Number");
      return false;
    } 
    return true;
  };
  async function updateUser() {
    try {
      const formValid = formValidation();
      if (formValid === false) {
        console.log("Form Validation Failed");
        return;
      }
      setLoader(true);
      const res = await api.post(`${API_URL}/auth/updateProfile`, {
        fullName: UserName,
        organizationName: OrgName,
        phoneNo: PhNo,
        password: password,
        profilePicture: imageSrc,
        userId: ID,
      });

      if (res.status === 200) {
        console.log("Profile detail submitted successfully");
        localStorage.setItem("profileupdate", true);
        dispatch(getProfile(ID));
        // dispatch(getProfileImage(userId));
        SuccessToast("Profile Updated Successfully");
        setLoader(false);
        setUserName("");
        setPhNo("");
        setOrgName("");
        setPassword("");
      }
    } catch (error) {
      setLoader(false);

      console.log("Error :", error);
    }
  }

  return (
    <>
      <Header headername={"Profile"} />
      {userLoading.loading && <ScreenLoader />}
      {loader && <ScreenLoader />}
      <div
        className="profile__body"
        style={{ zIndex: "3", position: "relative" }}
      >
        <div className="profile__main">
          <label className="profile__main-image" htmlFor="file-input">
            <img
              src={imageSrc}
              alt="profile-img"
              style={{ cursor: "pointer" }}
              className={
                imageSrc !== defaultprofile
                  ? "profile-img-updated"
                  : "profile-img"
              }
            />
          </label>
          <input
            id="file-input"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <div className="profile__main-values">
            <div className="profile__main-input">
              <label htmlFor="inputField">User name</label>
              <input
                type="text"
                id="inputField"
                placeholder="Jimmy Carter"
                value={UserName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            {Userrole === "organization" ? (
              <div className="profile__main-input4">
                <label htmlFor="inputField">Organization name</label>
                <input
                  readOnly
                  type="text"
                  id="inputField"
                  value={OrgName}
                  placeholder="Jimmy & Co."
                  onChange={(e) => setOrgName(e.target.value)}
                />
              </div>
            ) : null}

            <div className="profile__main-input2">
              <label htmlFor="inputField">Email address</label>
              <input
                readOnly
                type="email"
                id="inputField"
                value={Email}
                placeholder="majidali.designer@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="profile__main-input1">
              <label htmlFor="inputField">Contact number</label>
              <input
                type="tel"
                id="inputField"
                placeholder="654-098-731"
                value={PhNo}
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}"
                // onChange={(e) => setPhNo(e.target.value)}
                onChange={(e) => handlePhoneNo(e.target.value)}
              />
            </div>
            {userData?.isGoogleSignIn || userData?.islinkedinSignIn ? null : (
              <>
                <div className="profile__main-input3">
                  <label htmlFor="inputField">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="inputField"
                    placeholder="**********"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      // const error = validatePassword(e.target.value);
                      // setUserPasswordError(error);
                    }}
                  />

                  <button onClick={togglePasswordVisibility}>
                    {" "}
                    {showPassword ? (
                      <img
                        src="/Images/Profile/eye.svg"
                        className="profile-eye"
                        alt="/"
                      />
                    ) : (
                      <img
                        src="/Images/Profile/eye-off.svg"
                        className="profile-eye"
                        alt=""
                      />
                    )}{" "}
                  </button>
                </div>
                {/* <div>
                {userPasswordError && (
                    <div className="error-message">{userPasswordError}</div>
                  )}
                </div> */}
              </>
            )}
          </div>

          <div className="profile__main-btn">
            <button onClick={updateUser}>Edit profile</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
