import React, { useCallback, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./Login.css";
import Button from "@mui/material/Button";
import { useMediaQuery } from "react-responsive";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { ErrorToast, SuccessToast } from "../../../Components/toast/Toast";
import { useDispatch } from "react-redux";
import { LinkdinAuth, signin } from "../../../services/redux/middleware/signin";
import { forgetPassword } from "../../../services/redux/middleware/signin";
import ScreenLoader from "../../../Components/loader/ScreenLoader";
import { debounce } from 'lodash';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [loading, setLoading] = useState(false);

  const [iconSrc, setIconSrc] = useState({
    input1: "/Images/Auth/at-sign1.svg",
    input2: "/Images/Auth/Icon.svg",
  });

  const handleFocus = (inputNumber, iconSource) => {
    setIconSrc((prevState) => ({
      ...prevState,
      [inputNumber]: iconSource,
    }));
  };

  const handleBlusr = (inputNumber, iconSource) => {
    setIconSrc((prevState) => ({
      ...prevState,
      [inputNumber]: iconSource,
    }));
  };

  const iconMappings = {
    1: {
      empty: "/Images/Auth/at-sign1.svg",
      nonEmpty: "/Images/Auth/at-sign.svg",
    },
    2: {
      empty: "/Images/Auth/Icon.svg",
      nonEmpty: "Images/Auth/lock-01.svg",
    },
  };
  const handleBlur = (inputNumber, inputValue, iconMappings) => {
    const isEmpty = !inputValue.trim();
    const iconSource = isEmpty
      ? iconMappings[inputNumber].empty
      : iconMappings[inputNumber].nonEmpty;

    // Update the icon source state
    setIconSrc((prevState) => ({
      ...prevState,
      [inputNumber]: iconSource,
    }));
  };
  const handleInput = (inputNumber, iconSource) => {
    setIconSrc((prevState) => ({
      ...prevState,
      [inputNumber]: iconSource,
    }));
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const logingoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const datas = await axios.get(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
      );

      try {
        const data = {
          // id: userId,
          email: datas?.data?.email,
          fullname: datas?.data?.name,
          isGoogleSignIn: true,
          islinkedinSignIn: false,
        };
        console.log("datas", datas);
        console.log("data only", datas?.data);

        dispatch(signin(data)).then((res) => {
          if (res?.payload?.status === 200) {
            setLoader(false);
            console.log(" google login data is ", data);
            SuccessToast("Google Login Success");
            console.log("google login response", res);

            localStorage.setItem("_id", res?.payload?.data?.id);
            localStorage.setItem("token", res?.payload?.token);
            localStorage.setItem(
              "profileupdate",
              res?.payload?.data?.profileUpdate
            );
            localStorage.setItem("role", res?.payload?.data?.role);
            localStorage.setItem("name", res?.payload?.data?.fullname);

            console.log("my role", res?.payload?.data?.role);

            if (res?.payload?.data?.profileUpdate) {
              navigate("/Dashboard");
            } else {
              navigate("/Profile");
            }
          } else {
            setLoader(false);
            ErrorToast(res?.payload?.message);
          }
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  const debouncedLinkedInLogin = useCallback(
    debounce((data) => {
      dispatch(LinkdinAuth(data)).then((res) => {
        if (res?.payload?.status === 200) {
          console.log("this is response", res);
          setLoader(false);
          localStorage.setItem("_id", res?.payload?.data?.id);
          localStorage.setItem("token", res?.payload?.token);
          SuccessToast("Linkedin login");
          
          // localStorage.setItem("profileupdate", res?.payload?.data?.profileUpdate);

          if (res?.payload?.data?.profileUpdate) {
            navigate("/Dashboard");
          } else {
            navigate("/Profile");
          }
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    }, 300),
    []
  );

  const { linkedInLogin } = useLinkedIn({
    clientId: "77oc0z5qmkqij2",
    redirectUri: `https://webapp.pocketfiler.com/linkedin`,
    scope: "openid,profile,email",
    onSuccess: (code) => {
      if (!loading) {
        try {
          const data = {
            code: code,
          };
          debouncedLinkedInLogin(data)
        } catch (error) {
          console.error("Error:", error);
        } finally {
        }
      }
    },

    onError: (error) => {
      console.log("error", error);
    },
  });

  async function LinkdinAuthLogin() {
    try {
      await linkedInLogin();
    } catch (error) {}
  }

  const formValidation = () => {
    if (!email) {
      ErrorToast("Please Enter Email");
      return false;
    } else if (!password) {
      ErrorToast("Please Enter Password");
      return false;
    }
  };

  async function login() {
    const res = formValidation();
    if (res === false) {
      return false;
    }
    setLoader(true);
    try {
      const data = {
        email: email,
        password: password,
      };
      localStorage.clear();
      dispatch(signin(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          console.log("login res", res?.payload?.data);
          localStorage.setItem("_id", res?.payload?.data?.id);
          localStorage.setItem("token", res?.payload?.token);
          localStorage.setItem("role", res?.payload?.data?.role);
          localStorage.setItem("name", res?.payload?.data?.fullname);

          SuccessToast("Signed In Successfully");
          navigate("/Dashboard");
          // localStorage.setItem("profileupdate", res?.payload?.data?.profileUpdate);

          if (res?.payload?.data?.profileUpdate) {
            navigate("/Dashboard");
          } else {
            navigate("/Profile");
          }
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const [isInputClicked, setIsInputClicked] = useState(false);

  const handleInputClick = () => {
    setIsInputClicked(true);
  };
  const [isPassClicked, setPassClicked] = useState(false);
  const handlePassClick = () => {
    setPassClicked(true);
  };

  return (
    <>
      {loader && <ScreenLoader />}
      <Container fluid className="login-main">
        <Row className="row-login ">
          <Col lg={6} md={12} sm={12} className="login-col-lhs">
            <div className="LHS-login">
              <div className="login-logo-div">
                <img src="/Images/Auth/pocketfiler - logo.svg" alt="logo" />
              </div>

              <h2 className="login-head">Login to your account</h2>
              <p className="login-subtxt">
                The faster you login, The faster we get to work
              </p>

              <div>
                <Form.Group className="email-div">
                  <Form.Label className="common-label">
                    Email address
                  </Form.Label>
                  <div className="email-input-contain">
                    <div className="email-input-div">
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        className="email-input"
                        onChange={(e) => setEmail(e.target.value)}
                        // onFocus={handleInputClick}
                        // onFocus={() =>
                        //   handleFocus("input1", "/Images/Auth/at-sign.svg")
                        // }
                        onBlur={(e) =>
                          handleBlur(1, e.target.value, iconMappings)
                        }
                        onInput={() =>
                          handleInput("input1", "/Images/Auth/at-sign.svg")
                        }
                      />
                    </div>
                    <img src={iconSrc.input1} alt="@" className="img-at" />
                    {/* {isInputClicked ? (
                      <img
                        src="/Images/Auth/at-sign.svg"
                        alt="@"
                        className="img-at"
                      />
                    ) : (
                      <img
                        src="/Images/Auth/at-sign1.svg"
                        alt="@"
                        className="img-at"
                      />
                    )} */}
                  </div>
                </Form.Group>
                <Form.Group className="email-div">
                  <Form.Label className="common-label label-diff">
                    Password
                  </Form.Label>
                  <div className="email-input-contain">
                    <div className="email-input-div">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        className="email-input"
                        onChange={(e) => setPassword(e.target.value)}
                        // onFocus={() =>
                        //   handleFocus("input2", "/Images/Auth/lock-01.svg")
                        // }
                        onBlur={(e) =>
                          handleBlur(2, e.target.value, iconMappings)
                        }
                        // onInput={() =>
                        //   handleInput("input2", "/Images/Auth/lock-01.svg")
                        // }
                        onInput={(e) => {
                          handleInput(
                            "input2",
                            e.target.value
                              ? iconMappings[2].nonEmpty
                              : iconMappings[2].empty
                          );
                        }}
                      />
                    </div>
                    <img src={iconSrc.input2} alt="/" className="img-lock" />

                    {/* {isPassClicked ? (
                      <img
                        src="/Images/Auth/lock-01.svg"
                        alt="lock"
                        className="img-lock"
                      />
                    ) : (
                      <img
                        src={iconSrc.input2}
                        alt="/"
                        className="img-lock"
                      />
                    )} */}

                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      className="img-eye"
                    >
                      {!showPassword ? (
                        <img src="/Images/Auth/eye-off.svg" alt="eyeoff" />
                      ) : (
                        <img src="/Images/Auth/eye.svg" alt="eyeoff" />
                      )}
                    </IconButton>
                  </div>
                </Form.Group>
              </div>
              <p
                className="forgot-txt"
                onClick={() => navigate("/Forgot-Password")}
              >
                Forgot password?
              </p>
              <Button className="Login-btn" onClick={login}>
                Login
              </Button>

              <div className="Or-div">
                <p className="or-login-txt">Or login with </p>
                <hr className="custom-hr"></hr>
              </div>
              <div className="options-login-div">
                <div
                  className="option-login "
                  onClick={() => {
                    LinkdinAuthLogin();
                  }}
                >
                  <img src="/Images/Auth/linkedin.svg" alt="Linkedin" />
                </div>
                <div
                  className="option-login"
                  onClick={() => {
                    logingoogle();
                  }}
                >
                  <img src="/Images/Auth/google.svg" alt="Google" />
                </div>
              </div>
              <p className="dont-txt">
                Don't have an account?{" "}
                <span
                  className="signup-span"
                  onClick={() => navigate("/SignUp")}
                >
                  {" "}
                  Sign up{" "}
                </span>
              </p>
            </div>
          </Col>
          <Col className="rhs-login-col" md={6}>
            <div className="login-img-div h-100">
              <img
                src="/Images/Auth/login-bg.png"
                alt="img"
                className="login-rhs-img"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
