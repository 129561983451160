import React, { useState, useEffect } from "react";
import "./Disputing.css";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import Dropdown from "react-bootstrap/Dropdown";
import Chatbox from "../../../Components/Chatbox/Chatbox";
import UploadDocument from "../../../Components/Modals/UploadDocuments/UploadDocument";
import ProjectDocs from "../../../Components/Modals/ProjectDocs/ProjectDocs";
import Header from "../../../Components/Header/Header";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useNavigation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { viewproject } from "../../../services/redux/middleware/Project/project";
import { viewProjectActivities } from "../../../services/redux/middleware/Project/project";
import ChatboxDispute from "../../../Components/Chatbox/ChatboxDispute/ChatboxDispute";
import { WithDrawDispute } from "../../../services/redux/middleware/Dispute/dispute";
import ScreenLoader from "../../../Components/loader/ScreenLoader";
import { SuccessToast, ErrorToast } from "../../../Components/toast/Toast";
// const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
//     <a
//       href="/"
//       ref={ref}
//       onClick={(e) => {
//         e.preventDefault();
//         onClick(e);
//       }}
//     >
//       {/* Custom image for toggle */}
//       <img
//         src="/Images/Projects/arrow.svg"
//         alt="Toggle Icon"
//         style={{ width: '20px', height: '20px' }} // Adjust size as needed
//       />
//     </a>
//   ));

const Disputing = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [reqModalShow, setReqModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const { projectid } = useParams();

  const ProjectData = useSelector(
    (state) => state?.getviewproject?.viewProject?.data
  );
  // console.log("Project",projectid, "Project DATA ", ProjectData);

  const ConvertDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  useEffect(() => {
    console.log("id is", projectid);
    dispatch(viewproject(projectid));
    console.log("viewing");
  }, [projectid]);

  // useEffect(() => {
  //   console.log("id is", projectid);
  //   dispatch(viewproject(projectid));
  //   console.log("viewing");
  // }, [projectid]);

  useEffect(() => {
    dispatch(viewProjectActivities(projectid));
    console.log("getting all activities");
  }, [projectid]);

  const UserProjectActivity = useSelector(
    (state) => state?.getAllProjectActivity?.allProjectActivity?.data
  );
  console.log("Project Activity user", UserProjectActivity);

  const formatTime = (createdAt) => {
    if (!createdAt) return ""; // handle case where createdAt is undefined or null

    const date = new Date(createdAt);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be converted to 12

    // Padding minutes with zero if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;
  };

  function OnChatBox() {
    navigate(`/ProjectActivities/chatBox/${projectid}`);
  }
  function OnProjectContributor() {
    navigate(`/ProjectContributor/${projectid}`);
  }

  async function handleWithdraw() {
    try {
      const data = {
        id: projectid,
      };
      dispatch(WithDrawDispute(data)).then((res) => {
        if (res?.payload?.status === 200) {
          setLoader(false);
          console.log("WIthdrawing", res?.payload?.data);

          SuccessToast("Withdraw Dispute Success");
          navigate("/Disputes");
        } else {
          setLoader(false);
          ErrorToast(res?.payload?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }
  return (
    <>
      <Header headername={"Projects"} />

      <>
        <div className="ProjectActivities__top-box ">
          <div className="ProjectActivities__top-box_header">
            <div className="ProjectActivities__top-box_header-txt">
              <h4>{ProjectData?.title}</h4>
              <div className="bg__text">
                {" "}
                <p>{ProjectData?.status}</p>{" "}
              </div>
            </div>

            <div className="ProjectActivities__top-box_header-btn">
              <>
                <button className="disputing__top-btn" onClick={handleWithdraw}>
                  Withdraw Dispute
                </button>
              </>
              {/* )} */}
            </div>
          </div>
          <div className="ProjectActivities__box-top">
            <div className="ProjectActivities__box">
              <p className="ProjectActivities__box1">
                Date <span>{ConvertDate(ProjectData?.createdAt)}</span>
              </p>
              <p className="ProjectActivities__box2">
                Type <span>{ProjectData?.type}</span>
              </p>
            </div>
          </div>
          <div className="ProjectActivities__txt">
            <p>{ProjectData?.description}</p>
          </div>
        </div>

        <div>
          <ChatboxDispute disputing={true} />
        </div>

        {/* <Chatbox /> */}
      </>
    </>
  );
};

export default Disputing;
