import React, { useRef, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";

// import ThirdPartyRecorderSDK from 'third-party-recorder-sdk';

const Call = () => {
  const { roomID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("ProjectID");
  const [userName, setUserName] = useState();

  const myMeetingRef = useRef(null);
  useEffect(() => {
    const username = localStorage.getItem("name");
    console.log("user name is ", username);
    setUserName(username);
  }, []);

  useEffect(() => {
    const myMeeting = async (element) => {
      // generate Kit Token
      const appID = 1051157449;
      const serverSecret = "4648e3f0a8c2a0e26fdfb54f726c2a00";
      const username = localStorage.getItem("name");

      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
        appID,
        serverSecret,
        roomID,
        Date.now().toString(),
        username
        // `${userData?.firstName} ${userData?.lastName}`
      );

      // Create instance object from Kit Token.
      const zc = ZegoUIKitPrebuilt.create(kitToken);
      // const recorder = new ThirdPartyRecorderSDK();

      // start the call
      zc.joinRoom({
        container: element,
        showPreJoinView: false,
        // maxUsers: 2,
        showLeavingView: false,
        turnOnCameraWhenJoining: false,
        showScreenSharingButton: false,
        showMyCameraToggleButton: false,
        onLeaveRoom: async () => {
          // try {
          //   const currentURL = window.location.href;
          //   const urlParts = currentURL.split("/");

          //     const data = await axios.get(
          //       `${API_URL}/api/meetingEnded/${urlParts[1]}`
          //     );
          //   window.location.href = `${data.data.url}`;
          // } catch (err) {
          //   console.log(err);
          // }
          navigate(`/ProjectActivities/${projectId}`);
        },

        scenario: {
          mode: ZegoUIKitPrebuilt.GroupCall,
        },
      });
    };

    if (myMeetingRef.current) {
      myMeeting(myMeetingRef.current);
    }

    // Cleanup function
    return () => {
      // Clear ref
      myMeetingRef.current = null;
    };
  }, [projectId, roomID, navigate]);

  return (
    <div>
      <div ref={myMeetingRef} style={{ width: "100vw", height: "100vh" }} />
    </div>
  );
};

export default Call;
