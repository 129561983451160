// import React from 'react';
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import "./AllDisputes.css";
import IconButton from "@mui/material/IconButton";

import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import AddProject from "../../Components/Modals/AddProject/AddProject";
import UpdateProject from "../../Components/Modals/UpdateProject/UpdateProject";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { getAllProject } from "../../services/redux/middleware/Project/project";
import ScreenLoader from "../../Components/loader/ScreenLoader";
import { useNavigate } from "react-router-dom";
import { DisputeData } from "../../services/redux/middleware/Dispute/dispute";
import Dispute from "../../Components/Modals/Dispute/Dispute";

const AllDisputes = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [USERID, setUserId] = useState("");
  const [opensearch, setOpenSearch] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedView, setSelectedView] = useState("");
  const [selectedEdit, setSelectedEdit] = useState("");
  const [selectedAddClient, setSelectedAddClient] = useState("");

  const [modalShowUpdate, setModalShowUpdate] = useState(false);
  const [modalShowClient, setModalShowClient] = useState(false);

  const [updateShow, setUpdateShow] = useState(false);
  const [noproject, setShowNoProject] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInput = () => {
    setOpenSearch((prevOpensearch) => !prevOpensearch);
  };

  const ConvertDate = (originalDateStr) => {
    const originalDate = new Date(originalDateStr);
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const userLoading = useSelector((state) => state?.getAllProjects);
  const DisputeAllData = useSelector(
    (state) => state?.getDisputeData?.myDisputeDATA
  );
  console.log("MY ALL DIsputes", DisputeAllData);

  const filteredDisputes = DisputeAllData?.data?.disputes?.filter((row) =>
    row?.dispute?.project?.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const handleChangePagitation = (event, value) => {
    const userid = localStorage.getItem("_id");
    const data = {
      id: userid,
      page: value ? value : 1,
    };
    dispatch(DisputeData(data));
    setCurrentPage(value);
  };
  console.log("currentpg", currentPage);

  const handleViewProject = (projectId) => {
    console.log("Selected View Project Row is", projectId);
    setSelectedView(projectId);
    navigate(`/ProjectActivities/${projectId}?dispute=${true}`);
  };

  const handleAddDispute = () => {
    setModalShow(true);
  };

  console.log("Selected Edit Row ID:", selectedEdit);

  useEffect(() => {
    const userid = localStorage.getItem("_id");
    console.log("user id ", userid);
    setUserId(userid);
    const data = {
      id: userid,
      page: 1,
    };
    dispatch(DisputeData(data));
  }, []);

  return (
   <>

      <Header headername={"Disputes"} />
      {userLoading.loading && <ScreenLoader />}

      <>
        {DisputeAllData?.data?.disputes?.length > 0 ? (
          <div className="profit-table">
            <div
              className="row-3 profittable__main "
              style={{ overflowX: "auto" }}
            >
              <div className="profit-table__main-data">
                <div className="table-above-row-info pb-0">
                  <div>
                    <h6 className="user-heading mb-0"> Disputes </h6>
                  </div>

                  <div className="projecttable__main-head">
                    <div className="search-mb-contain">
                      <div
                        className={isMobile ? "d-block" : "d-none"}
                        onClick={handleSearchInput}
                      >
                        <img src="/Images/Contract/search.svg" alt="search" />
                      </div>
                    </div>
                    <div className={isMobile ? "d-none" : "search-input-icon"}>
                      <img
                        src="/Images/Projects/search.svg"
                        alt="search-icon"
                        className="search-icon"
                        style={{
                          position: "absolute",
                          left: "22px",
                          top: "45%",
                          transform: "translateY(-50%)",
                        }}
                      />
                      <input
                        type="text"
                        placeholder="Search disputes..."
                        className="search-input"
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                    <div className="drop-main">
                      <Dropdown className="drop-week">
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="dropdown-week"
                        >
                          <img src="/Images/Projects/calender.svg" alt="/" />{" "}
                          <span>This Year</span>
                          <img
                            className="dropdown__image-project"
                            src="/Images/HelpCenter/arrow.svg"
                            alt="arrow"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="projecttable__main-headingbtn">
                      <button onClick={() => setModalShow(true)}>
                        Add Dispute
                      </button>
                    </div>
                    <Dispute
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                </div>

                {opensearch && (
                  <div className="search-input-icon  ">
                    <img
                      src="/Images/Projects/search.svg"
                      alt="search-icon"
                      className="search-icon-allcontract"
                      style={{
                        position: "absolute",
                        left: "22px",
                        top: "45%",
                        // transform: "translateY(-50%)",
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Search disputes..."
                      className="search-input-contract"
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                )}

                <p className="total-contracts-txt">
                  Total {filteredDisputes && filteredDisputes.length} disputes
                </p>
                <TableContainer
                  sx={{ boxShadow: "none", minHeight: "400px" }}
                  className="project-table-container"
                >
                  <Table sx={{ overflowX: "auto" }} aria-label="simple table">
                    <TableHead style={{ height: "51px", borderRadius: "15px" }}>
                      <TableRow className="columns-name">
                        <TableCell
                          className="column-head"
                          style={{
                            textAlign: "left",
                            borderTopLeftRadius: "15px",
                            width: "25%",
                          }}
                        >
                          Project Name
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left", width: "20%" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left", width: "30%" }}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          className="column-head"
                          style={{ textAlign: "left" }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {filteredDisputes?.length > 0 ? (
                      <TableBody>
                        {filteredDisputes?.map((row, index) => (
                          <TableRow
                            style={{ height: "75px" }}
                            key={index}
                            onClick={() =>
                              handleViewProject(row?.dispute?.project?.id)
                            }
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                color: "#606060",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "160%",
                                textAlign: "left",
                                cursor: "pointer",
                                letterSpacing: "0.8px",
                              }}
                            >
                              {row?.dispute?.project?.title}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                color: "#0A1126",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "160%",
                                textAlign: "left",
                                cursor: "pointer",
                                letterSpacing: "0.8px",
                              }}
                            >
                              {ConvertDate(row?.dispute?.createdAt)}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                color: "#0A1126",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "160%",
                                textAlign: "left",
                                cursor: "pointer",
                                letterSpacing: "0.8px",
                              }}
                            >
                              {row?.dispute?.project?.description}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <p
                                style={{
                                  // color: "#166FBF",
                                  color:
                                    row?.dispute?.status === "inprogress"
                                      ? "#D32121"
                                      : row?.dispute?.status === "Withdrawn"
                                      ? "#166FBF"
                                      : "none",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "160%",
                                  textAlign: "center",
                                  // cursor: "pointer",
                                  // background: "#F1F6FB",
                                  borderRadius: "50px",
                                  background:
                                    row?.dispute?.status === "inprogress"
                                      ? "#FBEDED"
                                      : row?.dispute?.status === "Withdrawn"
                                      ? "#F1F6FB"
                                      : "none",
                                  height: "39px",
                                  width: "105px",
                                  letterSpacing: "0.8px",
                                  // paddingTop: "10px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {row?.dispute?.status}
                              </p>
                            </TableCell>

                            {/* <TableCell
                           component="th"
                           scope="row"
                           style={{
                             color: "#0A1126",
                             fontSize: "14px",
                             fontStyle: "normal",
                             fontWeight: "500",
                             lineHeight: "160%",
                             textAlign: "left",
                             cursor: "pointer",
                             letterSpacing: "0.5px",
                             // gap: "10px",
                             // display: "flex",
                           }}
                         >
                           {row.more}

                           
                           <img
                             src="/Images/Projects/edit.svg"
                             alt="/"
                             style={{
                               paddingRight: "8px",
                               width: "18px",
                               height: "18px",
                             }}
                           />
                           <img
                             src="/Images/Projects/more.svg"
                             alt="/"
                             onClick={() => setUpdateShow(true)}
                             style={{ width: "18px", height: "18px" }}
                           />
                           <UpdateProject
                             show={updateShow}
                             onHide={() => setUpdateShow(false)}
                           />
                         </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <div className="pt-2 ps-1">
                      <h5>No Data Found</h5>

                      </div>
                    )}
                  </Table>
                </TableContainer>
                <div className="page-table__pagenation">
                  <Stack spacing={2}>
                    <div className="custom-pagination-container">
                      <Pagination
                        count={DisputeAllData?.data?.totalPages}
                        page={currentPage}
                        // count={10}
                        size={"18px"}
                        sx={{
                          fontSize: "1px",
                        }}
                        siblingCount={isMobile ? 0 : 1}
                        // boundaryCount={1}
                        shape="rounded"
                        onChange={handleChangePagitation}
                      />
                    </div>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="Add-project-main  ">
            <div className="Add-project-inner">
              <div className="Add-project">
                <div className="add-project__value dispute-margin">
                  <img src="/Images/Dashboard/no-dispute.svg" alt="/" />
                  <h6 className="mb-0">No Disputes</h6>
                  <p>You have currently no disputes at this moment</p>
                  <button
                    onClick={() => handleAddDispute()}
                    // onClick={() => setModalShow(true)}
                  >
                    Add Disputes
                  </button>
                </div>

                {modalShow && (
                  <Dispute
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </>
      </>
   
  );
};

export default AllDisputes;
